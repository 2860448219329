import React from 'react';
import { FaStarOfLife } from 'react-icons/fa';

const MandatoryFieldsNotice = () => (
  <div className="mt-4 flex gap-2 items-center">
    <FaStarOfLife className="text-accent" />
    <p className="text-sm text-gray-400 inline-block">
      All fields are mandatory in this section
    </p>
  </div>
);

export default MandatoryFieldsNotice;
