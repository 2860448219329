import React from 'react';
import { FiChevronLeft, FiFileText } from 'react-icons/fi';
import { IoIosHelpCircleOutline } from 'react-icons/io';
const RegisterationHeader = ({ heading,handlePrevious }) => (
    <div className="flex justify-between items-center">
    <div className="flex items-center gap-1">
      <FiChevronLeft
        className="text-2xl  font-semibold text-coalBlack cursor-pointer min-w-10 min-h-10 rounded-full hover:bg-slate-50 transition-colors duration-100 ease-in-out md:text-base p-2"
        onClick={handlePrevious}
      />
      <p className="capitalize text-2xl !md:text-base font-medium text-coalBlack">
        {heading}
      </p>
    </div>
    <div className="bg-white p-1 rounded-xl border border-gray-300">
      <IoIosHelpCircleOutline className="text-2xl text-primary_v2" />
    </div>
  </div>
  
);

export default RegisterationHeader;
