import React, { useContext, useEffect, useState, useCallback } from 'react';
import MobileModel from '../Modals/MobileModel';
import { BiRupee } from 'react-icons/bi';
import Select from 'react-select';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import MainOddsPopup from '../common/oddsPopup/MainOddsPopup';
import { purchasesCredit, purchasesCreditValidation } from '../Validation';
import { useFormik } from 'formik';
import ConfirmPopup from '../confirmPopup/ConfirmPopup';
import toast from 'react-hot-toast';
import { EditProfileLoader, Loader } from '../common/loader';
import {
  checkMonthlyDue,
  CreditPurchases,
  CreditPurchasesPaymentSuccess,
  getCreditBasePrice,
  getMonthlyDue,
  getUserApi,
  PayMonthlyDue,
} from '../../apis';
import { AppContext } from '../../appContext';
import useRazorpay from 'react-razorpay';
import LogoImage from '../../Assets/odds_logo_blue.png';
import moment from 'moment';

function PayLastMonthDue({ closeFunction }) {
  const { loggedInUser } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [conformPopUp, setConformPopUp] = useState(false);
  const [loading, setloading] = useState(false);
  const [dueAmountDetails, setDueAmountDetails] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState(null);

  function handelConfirmPopUp() {
    setConformPopUp(!conformPopUp);
  }

  const message = (
    <p className="baseText">Are you sure you want to make this payment?</p>
  );
  const paymentSuccess = async () => {
    closeFunction();
    toast.success('Amount paid successfully');
  };
  const toGetOrderId = async () => {
    console.log(dueAmountDetails);
    console.log(dueAmountDetails?.PendingDueRecords);
    // console.log(dueAmountDetails?.PendingDueRecords[0]);
    // console.log(dueAmountDetails?.PendingDueRecords[0]?.item?._id,);
    
    setloading(true);
    const payload = {
      hospitalId: loggedInUser?.data?.hospitalProfile?._id,
      LedgerId: dueAmountDetails?._id,
    };
    try {
      const res = await PayMonthlyDue(payload);
      if (res.status === 200) {
        const dueAmt = res?.data?.order?.amount_due / 100;
        if (dueAmountDetails?.total_amount === dueAmt) {
          handlePayment(res?.data?.order?.id);
        }
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message || 'Something went wrong');
    } finally {
      setloading(false);
    }
  };
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(
    (order) => {
      const order_id = order;
      // Ensure order.id is defined
      if (!order_id) {
        console.error('Order ID is required for payment.');
        return;
      }
      const amountInPaise = order.amount * 100; // Convert amount to paise (smallest currency unit)
      const options = {
        key: process.env.REACT_APP_RAZARPAY_ID, // Your Razorpay key
        amount: amountInPaise.toString(), // Amount in paise
        currency: 'INR',
        name: 'ODDS',
        //description: "Test Transaction",
        image: LogoImage, // Uncomment if you have a logo
        order_id: order_id,
        handler: (response) => {
          console.log('Payment Success', response);
          handelConfirmPopUp();
          const payload = {
            hospitalId: order.hospitalid,
            razarpayOrderId: order_id,
          };
          paymentSuccess(payload);
        },
        prefill: {
          name: order.name,
          // email: "youremail@example.com",
          contact: order.phoneno,
        },
        // notes: {
        //   address: "Razorpay Corporate Office",
        // },
        theme: {
          color: '#1648CE',
        },
        modal: {
          ondismiss: () => {
            handelConfirmPopUp();
            console.log('Payment Failed or Closed');
            toast.error('Payment Cancelled');
            closeFunction();
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );
  function getDueMonth(prop) {
    const date = new Date();
    date.setMonth(prop - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }
  function canPayBillForCurrentMonth() {
    return true;
    const today = new Date(); // Get today's date
    const currentYear = today.getFullYear(); // Get the current year
    const currentMonth = today.getMonth(); // Get the current month (0 = January, 11 = December)

    // Get the last day of the current month
    const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);
    const lastDayOfCurrentMonth = new Date(firstDayOfNextMonth - 1);

    // Check if today is after the last day of the current month
    return today > lastDayOfCurrentMonth;
  }
  const toCheckMonthlyDue = async (id) => {
    try {
      const res = await checkMonthlyDue(id);
      if (res.status === 200) {
        console.log(res);
        setDueAmountDetails(res?.data?.data?.PendingDueRecords[0]?.item);
        if(res?.data?.data?.PendingDueRecords.length>0){
          const result = res?.data?.data?.PendingDueRecords[0]?.Transaction?.reduce(
            (acc, item) => {
              item.consultation_id.forEach((consultation) => {
                const consType = consultation.consType.toLowerCase(); // Virtual or InPerson
                const specialization = item.Specalaization[0]?.name; // Get specialization name

                if (!specialization) return; // Skip if no specialization

                if (consType === 'inperson') {
                  acc.inPerson.total += 1; // Increment total in-person count

                  if (!acc.inPerson.specializations[specialization]) {
                    acc.inPerson.specializations[specialization] = 0;
                  }
                  acc.inPerson.specializations[specialization] += 1; // Increment specialization count for in-person
                } else if (consType === 'virtual') {
                  acc.virtual.total += 1; // Increment total virtual count

                  if (!acc.virtual.specializations[specialization]) {
                    acc.virtual.specializations[specialization] = 0;
                  }
                  acc.virtual.specializations[specialization] += 1; // Increment specialization count for virtual
                }
              });

              return acc;
            },
            {
              inPerson: { total: 0, specializations: {} }, // Structure for inPerson
              virtual: { total: 0, specializations: {} }, // Structure for virtual
            }
          );
          setPaymentHistory(result);
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    toCheckMonthlyDue(loggedInUser?.data?.hospitalProfile?._id);
  }, [loggedInUser]);

  const modelBody = (
    <>
      {loader ? (
        <div className="min-h-44 flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="text-coalBlack">
          <div className="flex justify-between items-center">
            <p className="headingText">Amount Due</p>
            <p
              className="subHeadingText text-navLink lg:block hidden cursor-pointer"
              onClick={closeFunction}
            >
              Discard
            </p>
          </div>
          {canPayBillForCurrentMonth() ? (
            <p className="subHeadingText mt-4">
              {moment(dueAmountDetails?.createdAt).format('MMMM')} ₹{' '}
              {dueAmountDetails?.total_amount}
            </p>
          ) : (
            <p className="mt-4 baseText text-red-500">
              *Payment is not allowed until the end of the month.
            </p>
          )}
          <section className="py-3 space-y-3">
            <div className="flex items-center gap-x-3 subHeadingText text-coalBlack">
              <p>Total Consultation</p>
              <p className="ms-auto">
                {dueAmountDetails?.TransactionRecord?.length}
              </p>
            </div>
            <div className="space-y-2 pb-2 border-b border-gray-200 border-dotted">
              <div className="flex items-center gap-x-3 baseText text-coalBlack">
                <p>In-Person</p>
                <p className="ms-auto">{paymentHistory?.inPerson?.total}</p>
              </div>
              {Object.entries(paymentHistory?.inPerson?.specializations).map(
                ([specialization, count]) => (
                  <div
                    key={specialization}
                    className="flex items-center gap-x-3 baseText text-coalBlack capitalize"
                  >
                    <p>{specialization}</p>
                    <p className="ms-auto">{count}</p>{' '}
                  </div>
                )
              )}
            </div>
            <div className="space-y-2 pb-2 border-b border-gray-200 border-dotted">
              <div className="flex items-center gap-x-3 baseText text-coalBlack">
                <p>Virtual</p>
                <p className="ms-auto">{paymentHistory?.virtual?.total}</p>
              </div>
              {Object.entries(paymentHistory?.virtual?.specializations).map(
                ([specialization, count]) => (
                  <div
                    key={specialization}
                    className="flex items-center gap-x-3 baseText text-coalBlack capitalize"
                  >
                    <p>{specialization}</p>
                    <p className="ms-auto">{count}</p>{' '}
                  </div>
                )
              )}
            </div>
            <div className="flex items-center gap-x-3 subHeadingText text-coalBlack">
              <p>To Pay</p>
              <p className="ms-auto">₹ {dueAmountDetails?.total_amount}</p>
            </div>
          </section>
        </div>
      )}
    </>
  );
  const modelFooter = (
    <>
      {!loader && (
        <div className="mt-10 mb-6">
          <SimpleButton
            title={`Pay ₹ ${dueAmountDetails?.total_amount}`}
            buttonType={'primary'}
            customClass={'w-full rounded-2xl '}
            onClickEvent={handelConfirmPopUp}
          />
        </div>
      )}
    </>
  );

  return (
    <section>
      <div className="lg:hidden">
        <MobileModel
          MobileModelBody={modelBody}
          MobileModelFooter={modelFooter}
          popupCloseFunction={closeFunction}
        />
      </div>
      <div className="lg:block hidden">
        <MainOddsPopup>
          <div className="min-w-96 py-4">
            {modelBody}
            {modelFooter}
          </div>
        </MainOddsPopup>
      </div>
      {conformPopUp && (
        <ConfirmPopup
          message={message}
          popupStatus={conformPopUp}
          handelConfirmPopUp={handelConfirmPopUp}
          onclickFunction={toGetOrderId}
          confirmtext={loading ? <EditProfileLoader /> : 'Yes'}
          canceltext={'No'}
        />
      )}
    </section>
  );
}

export default PayLastMonthDue;
