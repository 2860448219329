import React, { useContext, useEffect, useState } from 'react';
import PayLastMonthDue from './PayLastMonthDue';
import { checkMonthlyDue } from '../../apis';
import { AppContext } from '../../appContext';
import RegularMonthDue from './RegularMonthDue';
import { Loader2 } from '../common/loader';

function PayBill({ closeFunction }) {
  const { loggedInUser } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [dueAmountDetails, setDueAmountDetails] = useState(null);
  const toCheckMonthlyDue = async (id) => {
    try {
      const res = await checkMonthlyDue(id);
      if (res.status === 200) {
        console.log(res);
        setDueAmountDetails(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    toCheckMonthlyDue(loggedInUser?.data?.hospitalProfile?._id);
  }, [loggedInUser]);
  if (loader) {
    return null;
  }
  return (
    <>
      {dueAmountDetails?.duepaid ? (
        <RegularMonthDue closeFunction={closeFunction} />
      ) : (
        <PayLastMonthDue closeFunction={closeFunction} />
      )}
    </>
  );
}

export default PayBill;
