import React from 'react';

const StepsIndicator = ({ step }) => (
    <div className="stepsBlock mt-4 p-2 rounded-xl flex items-center gap-2 shadow-sm md:shadow-none max-w-md">
    <p className="inline-block text-sm font-semibold text-black whitespace-nowrap">
      step {step}/2
    </p>
    <div className="w-full h-2 bg-accent/[22%] rounded-full relative max-w-md">
      <div
        className={`${
          step == 1 ? 'w-1/2' : 'w-full'
        } h-2 rounded-full bg-accent absolute top-0 left-0`}
      ></div>
    </div>
  </div>
);

export default StepsIndicator;
