import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  ForgotPasswordChangepasswordApi,
  ForgotPasswordVerifyotpApi,
  ForgotPasswordsendotpApi,
  SignupApi,
  getProfilebyId,
  loginApi,
} from '../../apis/index.js';
import {
  ForgotPasswordEmailValidation,
  ForgotPasswordOTPValidation,
  ResetPasswordValidation,
  SignInValidation,
  SignupValidation,
  WltSignInValidation,
} from '../../components/Validation/index.js';
import OneSignal from 'react-onesignal';
import Countdown from '../../components/Countdown/Countdown.js';
import OtpInput from 'react-otp-input';
import LogoImage from '../../Assets/img/logos/mash_yellow.png';
import Cover from '../../Assets/img/covers/Login.jpg';
import { assignBucketUrl } from '../../utils/helperFunctions.js';
import ProfileDefault from '../../Assets/img/covers/userDefault.png';
import { GoogleLogin,useGoogleLogin,useGoogleOneTapLogin } from '@react-oauth/google';
import { verifyToken } from '../../apis/index.js';
import { AppContext } from '../../appContext/index.js';
import { InputErrorMessage } from '../../components/errorMessages/index.js';
import { EditProfileLoader } from '../../components/common/loader.js';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import { FcGoogle } from 'react-icons/fc';
import { FaSquareFacebook } from 'react-icons/fa6';
import MobileModel from '../../components/Modals/MobileModel.js';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';
import RightBlock from '../../components/signUpLatyout/RightBlock.js';
import MandatoryLabel from '../../components/common/label.js';

export default function GoogleSignIn({
  formGreetings,
  formSubGreetings,
  formType,
}) {
  const appContext = useContext(AppContext);
  const { setIsLoggedIn } = appContext;
  const navigate = useNavigate();
  const location = useLocation();
  async function runOneSignal(id) {
    try {
     console.log(id)
     OneSignal.login(id)
      console.log('Login successful');
    } catch (error) {
      console.error('Error logging in:', error);
    }
  }
  const onVerifyToken = async (values) => {
    try {
      setLoader(true);
      const response = await verifyToken(values);
      const data = response.data;
      if (response.status === 200) {
        localStorage.setItem('userId', response.data.userid);
        const res2 = await getProfilebyId(response.data.userid);
        if (res2.status === 200) {
          var hospitaldata = res2?.data?.Profile?.hospitalProfile;
          console.log(hospitaldata)
          if (hospitaldata) {
            if (!hospitaldata?.isApproved) {
              navigate('/Registration');
            } else {
              await runOneSignal(hospitaldata?._id)
              localStorage.setItem('token', response.data.token);
               navigate('/');
            }
          } else {
            navigate('/Registration');
          }
        }
      } 
    } catch (error) {
      console.log(error)
      setLoader(false);
      toast.error(error?.response?.data?.message, { id: '001' });
    }
  };
  const responseMessage = (response) => {
    console.log(response)
     const values = { token: response.credential };
     onVerifyToken(values);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const formik = useFormik({
    initialValues: { email: '', keepLoggedIn: false },
    validationSchema: WltSignInValidation,
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setLoader(true);
        const res = await loginApi({ email });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/otp', {
            state: {
              email: formik.values.email,
              keepLoggedIn: formik.values.keepLoggedIn,
            },
          });
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      }
    },
  });

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const [loader, setLoader] = useState(false);
  return (
    <>
     
      <section className="sign-in w-full h-full min-h-svh max-h-svh overflow-hidden flex items-center justify-center bg-white">
        <div className="sign-in-left flex  items-center flex-grow w-1/2 relative">
          <section className="flex flex-col gap-2 max-h-screen min-h-screen overflow-y-auto scrollbar w-full bg-[#FAFAFE] px-4 py-10 ">
            <div className="welMessage space-y-3">
              <h1 className="text-2xl font-medium text-center">
                {formGreetings}
              </h1>
              <p className="text-sm font-normal text-center max-w-80 text-primary_v2 mx-auto">
                {formSubGreetings}
              </p>
            </div>
            <div className="logoContainer flex justify-center mt-4">
              <div className="inline-flex mx-auto gap-2 items-center">
                <div><img src={require('../../Assets/odds_logo_blue.png')} className='size-10 object-contain'/></div>
                <p className="text-lg font-semibold text-coalBlack">ODDS</p>
              </div>
            </div>
            <div className="signInFormContainer w-full mt-16 max-w-sm mx-auto lg:max-w-[400px] lg:min-w-[400px] ">
              {/* <div className="signInWithGoogle flex justify-center items-center gap-8 w-full">
                <div className="lg:hidden bg-white  shadow-blue_dropshadow px-4 py-2 rounded-2xl" onClick={() => loginwithgoogle()}>
                  <SignInWithGoogleCard platform={'google'} icon={'google'} />
                </div>
                <div className="lg:block hidden w-full bg-[#F4F7FE] text-center  rounded-2xl px-4 py-3" onClick={() => loginwithgoogle()}>
                  <SignInWithGoogleCard
                    platform={'Sign in with Google'}
                    icon={'google'}
                  />
                </div>
              </div> */}
              <div className="signInWithGoogle flex justify-center items-center gap-8 w-full">
               
                  <GoogleLogin text='continue_with'
                  onSuccess={responseMessage} onError={errorMessage} />
              </div>
               
              <div className='my-4 text-navLink text-sm font-normal text-center relative after:w-full after:h-px after:bg-navLink after:contents[""] after:absolute after:-translate-y-1/2 after:top-1/2 after:left-2 '>
                <span className="px-4 bg-[#FAFAFA] relative z-10 ">or</span>
              </div>
              <div className="signInFormField mt-8">
                {formType == 'SIGNUP' ? <SignUpForm /> : <LogInForm runOneSignal={runOneSignal} />}
              </div>
            </div>
          </section>
        </div>
        <RightBlock />
      </section>
    </>
  );
}

function SignInWithGoogleCard({ platform, icon }) {
  return (
    <div className="inline-flex justify-center items-center gap-2 capitalize text-sm text-primary_v2 font-medium bg-inherit  min-w-28 text-center  rounded-xl  cursor-pointer">
      {icon == 'google' ? <FcGoogle /> : <FaSquareFacebook />}
      {platform}
    </div>
  );
}
function SignUpForm() {
  const formik = useFormik({
    initialValues: { email: '', name: '', password: '', terms: false },
    validationSchema: SignupValidation,
    onSubmit: async (values) => {
      const { name, email, password, terms } = values;
      console.log(name);
      console.log(email);
      console.log(password);
      try {
        setLoader(true);
        const res = await SignupApi({
          email: email,
          isActive: true,
          // userName: name,
          password: password,
          isTermsAccepted: true,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          navigate('/sign-in');
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      }
    },
  });
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState('password');
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    formik.setFieldValue(name, checked);
  };
  return (
    <form className="signInForm space-y-3" onSubmit={formik.handleSubmit}>
      {/* <div className="relative">
        <MandatoryLabel title="Name" />
        <input
          type="text"
          name="name"
          className={
            Boolean(formik.touched.name) && Boolean(formik.errors.name)
              ? 'form-control border-danger transparent-input-border'
              : 'form-control transparent-input-border'
          }
          id="name"
          placeholder="Name"
          value={formik.values.name}
          onChange={handleChange}
        />
        <InputErrorMessage error={formik.touched.name && formik.errors.name} />
      </div> */}
      <div className="relative">
        <MandatoryLabel title="Email" />
        <input
          type="text"
          name="email"
          className={
            Boolean(formik.touched.email) && Boolean(formik.errors.email)
              ? 'form-control border-danger transparent-input-border'
              : 'form-control transparent-input-border'
          }
          id="email"
          placeholder="Email"
          value={formik.values.email}
          onChange={handleChange}
        />
        <InputErrorMessage
          error={formik.touched.email && formik.errors.email}
        />
      </div>
      <div className="relative">
        <MandatoryLabel title="password" />
        <div className="relative">
          <input
            type={passwordType}
            name="password"
            className={
              Boolean(formik.touched.password) &&
              Boolean(formik.errors.password)
                ? 'form-control border-danger transparent-input-border'
                : 'form-control transparent-input-border'
            }
            id="password"
            placeholder="Password "
            value={formik.values.password}
            onChange={handleChange}
          />
          <div
            className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
            onClick={handelPasswordType}
          >
            {passwordType == 'password' ? (
              <IoEyeOff />
            ) : passwordType == 'text' ? (
              <IoEye />
            ) : (
              <></>
            )}
          </div>
        </div>
        <InputErrorMessage
          error={formik.touched.password && formik.errors.password}
        />
      </div>
      <div className="termsCondition">
        <div className=" flex items-start mt-6">
          <input
            type="checkbox"
            className="mt-0.5"
            id="terms"
            name="terms"
            onChange={handleCheckboxChange} // Handles state changes
            checked={formik.values.terms} // Controls the checked state based on Formik's state
          />
          <label
            className="text-xs text-primary_v2 ms-1 select-none"
            htmlFor="terms"
          >
            I agree with the Terms of Service & Privacy Policy
          </label>
        </div>
        <InputErrorMessage
          error={formik.touched.terms && formik.errors.terms}
        />
      </div>
      <div className="signButton !mt-14 space-y-2">
        <button
          className="btn btn_primary_black text-white w-full rounded-xl"
          type="submit"
        >
          {!loader ? (
            'Sign Up'
          ) : (
            <div className="text-xs">
              <EditProfileLoader />
            </div>
          )}
        </button>
        <p
          className="text-sm text-accent font-normal text-center cursor-pointer select-none"
          onClick={() => navigate('/log-in')}
        >
          Have an account? Log in
        </p>
      </div>
    </form>
  );
}
function LogInForm({runOneSignal}) {
  const formik = useFormik({
    initialValues: { email: '', password: '', keepMeLoggedIn: false },
    validationSchema: SignInValidation,
    onSubmit: async (values) => {
      const { email, password } = values;

      try {
        setLoader(true);
        const res = await loginApi({
          email: email,
          password: password,
          keepLoggedIn: true,
        });
        if (res.status === 200) {
          localStorage.setItem('userId', res.data.userId);
          const res2 = await getProfilebyId(res.data.userId);
          if (res2.status === 200) {
            var hospitaldata = res2?.data?.Profile?.hospitalProfile;
            if (hospitaldata) {
              if (!hospitaldata?.isApproved) {
                navigate('/Registration');
              } else {
                await runOneSignal(hospitaldata?._id)
                localStorage.setItem('token', res.data.token);
                 navigate('/');
              }
            } else {
              navigate('/Registration');
            }
          }
        }
      } catch (error) {
        setLoader(false);
        console.log(error)
        const message = error.response.data.error || error.response.statusText;
        toast.error(message, { id: 'error' });
      }
    },
  });

  
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState('password');
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [forgotPasswordBlock, setForgotPasswordBlock] = useState(
    'forgotPasswordBlock'
  ); //verificationBlock //resetPasswordBlock
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  function handelForgotPasswordModal() {
    setForgotPasswordModal(!forgotPasswordModal);
  }

  return (
    <>
      <form className="LogInForm space-y-3 " onSubmit={formik.handleSubmit}>
        <div className="relative">
          <MandatoryLabel title="Email" />
          <input
            type="text"
            name="email"
            className={
              Boolean(formik.touched.email) && Boolean(formik.errors.email)
                ? 'form-control border-danger transparent-input-border'
                : 'form-control transparent-input-border'
            }
            id="email"
            placeholder="Email"
            value={formik.values.email}
            onChange={handleChange}
          />
          <InputErrorMessage
            error={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="relative">
          <MandatoryLabel title="password" />
          <div className="relative">
            <input
              type={passwordType}
              name="password"
              className={
                Boolean(formik.touched.password) &&
                Boolean(formik.errors.password)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="password"
              placeholder="Password "
              value={formik.values.password}
              onChange={handleChange}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelPasswordType}
            >
              {passwordType == 'password' ? (
                <IoEyeOff />
              ) : passwordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={formik.touched.password && formik.errors.password}
          />
        </div>

        <div className="flex justify-between items-center">
          <div className=" flex items-start ">
            <input
              type="checkbox"
              className="mt-0.5"
              id="keepMeLoggedIn"
              name="keepLoggedIn"
              onChange={(e) =>
                formik.setFieldValue(
                  'keepMeLoggedIn',
                  e.target.checked ? true : false
                )
              }
              value={formik.values.keepMeLoggedIn}
            />
            <label
              className="text-xs text-primary_v2 ms-1 select-none"
              htmlFor="keepMeLoggedIn"
            >
              Remember Me{' '}
            </label>
          </div>
          <p
            className="text-sm text-accent font-normal text-center cursor-pointer select-none"
            onClick={handelForgotPasswordModal}
          >
            Forgot Password?
          </p>
        </div>
        <div className="signButton !mt-[74px] space-y-2">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Log In'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>

          <p
            className="text-sm text-accent font-normal text-center mt-20 cursor-pointer select-none"
            onClick={() => navigate('/sign-up')}
          >
            Don’t have an account? Join us
          </p>
        </div>
      </form>

      {forgotPasswordModal && (
        <ForgotPasswordScreens
          handelForgotPasswordModal={handelForgotPasswordModal}
        />
      )}
    </>
  );
}

function ForgotPasswordScreens({ handelForgotPasswordModal }) {
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [forgotPasswordBlock, setForgotPasswordBlock] = useState(1); //verificationBlock //resetPasswordBlock
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: ForgotPasswordEmailValidation,
    onSubmit: async (values) => {
      const { email } = values;

      try {
        setLoader(true);
        const res = await ForgotPasswordsendotpApi({ email });
        if (res.status === 200) {
          toast.success(res.data.message);
          handelForgotPasswordBlocks(2);
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        setLoader(false);
      }
    },
  });
  const formik2 = useFormik({
    initialValues: { otp: '' },
    onSubmit: async (values) => {
      const { email } = formik.values;
      if (otp.length < 4) {
        setIsWrongOtp(true);
        setErrorMsg('Please enter 6 digits otp');
        return;
      }
      try {
        setLoader(true);
        const res = await ForgotPasswordVerifyotpApi({
          email: email,
          otp: otp,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          formik3.setFieldValue('userid', res.data?.user?._id);

          handelForgotPasswordBlocks(3);
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        setLoader(false);
      }
    },
  });
  const formik3 = useFormik({
    initialValues: { newpassword: '', confirmpassword: '', userid: '' },
    validationSchema: ResetPasswordValidation,
    onSubmit: async (values) => {
      const { newpassword, confirmpassword, userid } = values;

      try {
        setLoader(true);
        const res = await ForgotPasswordChangepasswordApi({
          userId: userid,
          password: newpassword,
        });
        if (res.status === 200) {
          toast.success(res.data.message);
          passwordUpdated();
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        setLoader(false);
      }
    },
  });

  const resendOtp = async () => {
    try {
      //setLoader(true);
      const payload = {
        email: formik.values.email,
      };
      const response = await ForgotPasswordsendotpApi(payload);
      if (response.status === 200) {
        // setLoader(false);
        toast.success(response.data.message);
      }
    } catch (error) {
      const message =
        error?.response?.data?.message || error?.response?.statusText;
      toast.error(message, { id: 'error' });
      // setLoader(false);
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };
  const handleChange3 = (e) => {
    e.preventDefault();
    formik3.handleChange(e);
  };
  const [loader, setLoader] = useState(false);
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  function handelConfirmPasswordType() {
    if (confirmPasswordType == 'text') {
      setConfirmPasswordType('password');
    } else if (confirmPasswordType == 'password') {
      setConfirmPasswordType('text');
    }
  }

  function handelForgotPasswordBlocks(prop) {
    setForgotPasswordBlock(prop);
  }

  function passwordUpdated() {
    setForgotPasswordBlock(1);
    handelForgotPasswordModal();
  }

  const forgotPasswordBlockHeader = (
    <>
      <h2 className="font-medium text-2xl ">Forgot password</h2>
      <p className="text-sm text-primary_v2 mt-4">
        Enter your email for the verification process, we will send 4 digits
        code to your email.
      </p>
    </>
  );
  const forgotPasswordBlockBody = (
    <>
      <form className="forgotPassword" onSubmit={formik.handleSubmit}>
        <div className="modelBody my-2 py-2">
          <div>
            <MandatoryLabel title="Email" />
            <input
              type="email"
              name="email"
              className={
                Boolean(formik.touched.email) && Boolean(formik.errors.email)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="emailll"
              placeholder="Email"
              value={formik.values.email}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.email && formik.errors.email}
            />
          </div>
        </div>
        <div className="modelFooter">
          <div className="mt-6">
            <button
              className="btn btn_primary_black text-white w-full rounded-xl"
              type="submit"
            >
              {!loader ? (
                'Continue'
              ) : (
                <div className="text-xs">
                  <EditProfileLoader />
                </div>
              )}
            </button>
          </div>
        </div>
      </form>
    </>
  );
  const verificationBlockHeader = (
    <>
      <h2 className="font-medium text-2xl ">Enter verification code</h2>
      <p className="text-sm text-primary_v2 mt-4">
        Enter the 4 digits code that you received on your email.
      </p>
    </>
  );
  const [isWrongOtp, setIsWrongOtp] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [otp, setOTP] = useState('');
  const handleOtpInput = (otp) => {
    setIsWrongOtp(false);
    setErrorMsg('');
    setOTP(otp);
  };
  const verificationBlockBody = (
    <form className="verifyotp" onSubmit={formik2.handleSubmit}>
      <div className="modelBody my-2 py-2 flex items-center justify-center">
        <OtpInput
          inputStyle={{
            marginLeft: '0',
            width: '3.375rem',
            height: '3.375rem',
            borderRadius: '12px',
            textAlign: 'center',
            border: '1px solid rgba(111, 117, 135, 0.16)',
          }}
          className={'me-2 text-center fs-3  w-50 otpInputt'}
          errorStyle={{ border: 'solid 1px red' }}
          hasErrored={isWrongOtp}
          isInputNum
          value={otp}
          onChange={(otp) => handleOtpInput(otp)}
          numInputs={4}
          separator={<span className="w-6"> </span>}
        />
      </div>
      <div className="modelFooter">
        {/* <p className="text-sm mt-2">
          Haven’t received code?{' '}
          <span className="ms-1 font-semibold text-accent cursor-pointer hover:underline underline-offset-2 ">
            Resend OTP
          </span>
        </p> */}
        <p>
          <Countdown initialSeconds={60} resendOtp={resendOtp} />
        </p>
        <div className="mt-6">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Continue'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );
  const resetBlockHeader = (
    <>
      <h2 className="font-medium text-2xl ">Reset password</h2>
      <p className="text-sm text-primary_v2 mt-4">
        Set the new password for your account so you can login and access all
        the features.
      </p>
    </>
  );
  const resetBlockBody = (
    <form className="resetpassword" onSubmit={formik3.handleSubmit}>
      <div className="modelBody my-2 py-2 space-y-4">
        <div className="newPasswordField">
          <MandatoryLabel title="New Password" />
          <div className="relative">
            <input
              type={passwordType}
              name="newpassword"
              className={
                Boolean(formik3.touched.newpassword) &&
                Boolean(formik3.errors.newpassword)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="newpassword"
              placeholder="New Password "
              value={formik3.values.newpassword}
              onChange={handleChange3}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelPasswordType}
            >
              {passwordType == 'password' ? (
                <IoEyeOff />
              ) : passwordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={formik3.touched.newpassword && formik3.errors.newpassword}
          />
        </div>
        <div className="confirmPasswordField">
          <MandatoryLabel title="Confirm Password" />
          <div className="relative">
            <input
              type={confirmPasswordType}
              name="confirmpassword"
              className={
                Boolean(formik3.touched.confirmpassword) &&
                Boolean(formik3.errors.confirmpassword)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="confirmpassword"
              placeholder="confirm Password "
              value={formik3.values.confirmpassword}
              onChange={handleChange3}
            />
            <div
              className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
              onClick={handelConfirmPasswordType}
            >
              {confirmPasswordType == 'password' ? (
                <IoEyeOff />
              ) : confirmPasswordType == 'text' ? (
                <IoEye />
              ) : (
                <></>
              )}
            </div>
          </div>
          <InputErrorMessage
            error={
              formik3.touched.confirmpassword && formik3.errors.confirmpassword
            }
          />
        </div>
      </div>
      <div className="modelFooter">
        <div className="mt-6">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
            // onClick={passwordUpdated}
          >
            {!loader ? (
              'Update Password'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>
      </div>
    </form>
  );
  return (
    <>
      {forgotPasswordBlock == 1 ? (
        <>
          <div className="block lg:hidden">
            <MobileModel
              MobileModelHeader={forgotPasswordBlockHeader}
              MobileModelBody={forgotPasswordBlockBody}
              popupCloseFunction={handelForgotPasswordModal}
            />
          </div>
          <div className="hidden lg:flex flex-col justify-center absolute top-0 left-0 z-10 w-full h-full bg-white">
            <div className="max-w-sm w-full m-auto">
              <div
                className="mb-8 flex gap-1 items-center text-primary_v2 cursor-pointer"
                onClick={passwordUpdated}
              >
                <HiOutlineArrowSmLeft className="text-xl" />
                <p>Back to sign in</p>
              </div>
              {forgotPasswordBlockHeader}
              {forgotPasswordBlockBody}
            </div>
          </div>
        </>
      ) : forgotPasswordBlock == 2 ? (
        <div>
          <div className="block lg:hidden">
            <MobileModel
              MobileModelHeader={verificationBlockHeader}
              MobileModelBody={verificationBlockBody}
              popupCloseFunction={handelForgotPasswordModal}
            />
          </div>
          <div className="hidden lg:flex flex-col justify-center absolute top-0 left-0 z-10 w-full h-full bg-white">
            <div className="max-w-sm w-full m-auto">
              <div
                className="mb-8 flex gap-1 items-center text-primary_v2 cursor-pointer"
                onClick={passwordUpdated}
              >
                <HiOutlineArrowSmLeft className="text-xl" />
                <p>Back to sign in</p>
              </div>
              {verificationBlockHeader}
              {verificationBlockBody}
            </div>
          </div>
        </div>
      ) : forgotPasswordBlock == 3 ? (
        <div>
          <div className="block lg:hidden">
            <MobileModel
              MobileModelHeader={resetBlockHeader}
              MobileModelBody={resetBlockBody}
              popupCloseFunction={handelForgotPasswordModal}
            />
          </div>
          <div className="hidden lg:flex flex-col justify-center absolute top-0 left-0 z-10 w-full h-full bg-white">
            <div className="max-w-sm w-full m-auto">
              <div
                className="mb-8 flex gap-1 items-center text-primary_v2 cursor-pointer"
                onClick={passwordUpdated}
              >
                <HiOutlineArrowSmLeft className="text-xl" />
                <p>Back to sign in</p>
              </div>
              {resetBlockHeader}
              {resetBlockBody}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
