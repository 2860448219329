import React from 'react';
import { BsCurrencyRupee } from 'react-icons/bs';

function TransitionCard({data, selectedMonth}) {
  console.log(data);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    
    const dayOfWeek = days[date.getUTCDay()];
    const dayOfMonth = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    
    return `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;
  };

  return (
    <>
    {data?.length ? (
      data.filter((item) => {
        const month = formatDate(item.createdAt).split(' ')[2];
        return month === selectedMonth;
      }).length ? (
        data.filter((item) => {
          const month = formatDate(item.createdAt).split(' ')[2];
          return month === selectedMonth;
        }).map((item, index) => (
          <div key={index} className="flex items-start gap-2">
            <div className='w-1/3'>
              <p className="capitalize line-clamp-1 !font-medium baseText text-coalBlack flex items-center">
              <BsCurrencyRupee /> {item.consultationCreditCount} 
              </p>
              <p className="text-navLink smallText">
                {item.status === 'pending' ? 'Consultation booking' : 'Consultation Completed'}
              </p>
            </div>
            <p className="capitalize smallText text-navLink ms-auto w-1/3 text-center">
              neurologist
            </p>
            <p className="capitalize smallText text-navLink ms-auto w-1/3 text-end">
              {formatDate(item.createdAt)}
            </p>
          </div>
        ))
      ) : (
        <div className="flex items-center gap-2">
          <p className="text-navLink baseText">No data available in this month</p>
        </div>
      )
    ) : (
      <div className="flex items-center gap-2">
        <p className="text-navLink baseText">No data available</p>
      </div>
    )}
  </>
  
    
  );
}

export default TransitionCard;
