import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useFormik } from 'formik';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { EditProfileLoader, Loader } from '../common/loader';
import { InputErrorMessage } from '../errorMessages';
import { FiChevronLeft } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import MainOddsPopup from '../common/oddsPopup/MainOddsPopup';
import { cancelSubscription, createSubscription, getSubscriptionPlan } from '../../apis';
import * as yup from 'yup';
import { AppContext } from '../../appContext';
import useRazorpay from 'react-razorpay';
import Moment from 'react-moment';

function ActiveSubscription({ resetRenewStatus, renewStatus,subscriptionId }) {
  console.log(renewStatus);
  const [planDetails, setPlanDetails] = useState(null);
  const { loggedInUser } = useContext(AppContext);
  const [plan, setPlan] = useState([]);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const planTypes = plan.map((data) => data.razarPayPlanId);
  let activeBg = 'bg-[#438883]/[10%]';
  let inActiveBg = 'bg-[#F7F7F7]';
  let activeText = 'text-[#438883]';
  let inActiveText = 'text-[#888888]';
  useEffect(() => {
    getPlan();
    setPlanDetails(loggedInUser?.data?.hospitalProfile?.subscriptionId);
  }, [loggedInUser]);

  const subscription = yup.object({
    subscription: yup
      .string()
      .oneOf(planTypes, 'Please select a subscription plan to continue')
      .required('Please select a subscription plan to continue'),
  });
  const formik = useFormik({
    initialValues: {
      subscription: subscriptionId || '',
    },
    onSubmit: async (values) => {
        const { subscription } = values;
        setBtnLoader(true);
        if (subscription === '') {
          toast.error('Please select any one option.');
          setBtnLoader(false);
          return;  // Stop further execution if no subscription is selected
        }
      
        const payload = {
          id: subscription,
        };
        
        try {
          console.log(values); // Logging input values
          console.log(payload); // Logging payload
      
          const res = await cancelSubscription(payload);
      
          if (res.status === 200) {
            toast.success("Subscription Cancelled");
          } else {
            // If the response status is not 200, handle it as an error (optional)
            toast.error("Failed to cancel subscription. Please try again.");
          }
        } catch (error) {
          console.error("Error cancelling subscription:", error);
          // Display error message from exception
          toast.error(`${error?.response?.data?.message || "Unknown error"}`);
        } finally {
          // This block will run regardless of success or error
          setBtnLoader(false);
        }
      },
      
  });
  const startDate = new Date(planDetails?.currentStart * 1000)
    .toLocaleString()
    .split(',')[0];
  const endDate = new Date(planDetails?.currentEnd * 1000)
    .toLocaleString()
    .split(',')[0];

  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const subscriptionTabs = (
    <form className="block ">
      <section className="space-y-4">
        <p className="subHeadingText mb-2">Active Plan</p>
        {plan.map((data, i) =>
          data?.razarPayPlanId === renewStatus.razarPayPlanId ? (
            <label
              className={`p-4 rounded-2xl border border-gray-200 relative max-w-sm mx-auto flex items-start min-h-28 gap-4 cursor-pointer ${
                formik.values.subscription === subscriptionId
                  ? activeBg
                  : inActiveBg
              } `}
              key={i}
            >
              <input
                type="radio"
                name="subscription"
                id="subscription"
                value={subscriptionId}
                checked={formik.values.subscription === subscriptionId}
                onChange={handleChange}
                hidden
              />
              <div
                className={`inline-flex justify-center items-center size-16 min-w-16 rounded-full ${
                  formik.values.subscription === subscriptionId
                    ? 'bg-white'
                    : 'bg-transparent'
                }`}
              >
                {/* <BiSolidDollarCircle className="size-9 text-gray-500" /> */}
                <div className="size-9 rounded-full">
                  {data?.planIcon != '' ? (
                    <img
                      src={data?.planIcon}
                      className="size-9 rounded-full object-cover"
                    />
                  ) : null}
                </div>
              </div>
              <div
                className={
                  formik.values.subscription === subscriptionId
                    ? activeText
                    : inActiveText
                }
              >
                <p className="subHeadingText  capitalize">{data?.planName}</p>

                <p className="baseText  capitalize">{data?.planDescription}</p>
                <div className="flex items-center mt-1">
                  {/* <p className='baseText capitalize' ></p> */}
                  <p className="baseText !font-medium capitalize">
                    {data?.period} ₹{data?.planAmount}
                  </p>
                </div>
              </div>
              <div
                className={`absolute top-3 right-3 ${
                  formik.values.subscription === subscriptionId
                    ? 'visible'
                    : 'invisible'
                }`}
              >
                <IoIosCheckmarkCircle className="text-2xl text-[#438883]" />
              </div>
            </label>
          ) : null
        )}
        <div className="text-coalBlack flex flex-wrap gap-x-3">
          <div className="flex items-center gap-x-1">
            <p className="baseText whitespace-nowrap">Start Date : </p>
            <p className="baseText whitespace-nowrap">{startDate}</p>
          </div>
          <div className="flex items-center gap-x-1 ms-auto">
            <p className="baseText whitespace-nowrap">End Date : </p>
            <p className="baseText whitespace-nowrap">{endDate}</p>
          </div>
        </div>
      </section>
      <div className="my-10 mb-20 max-w-sm mx-auto ">
        <p className="mb-2">
          <InputErrorMessage
            error={formik.touched.subscription && formik.errors.subscription}
          />
        </p>
        <SimpleButton
          title={btnLoader ? <EditProfileLoader /> : 'Cancel Subscription'}
          onClickEvent={handleSubmit}
          buttonType={'primary'}
          customClass={'w-full rounded-2xl '}
        />
      </div>
    </form>
  );
  const getPlan = async () => {
    const res = await getSubscriptionPlan();
    if (res.status == 200) {
      console.log(res?.data?.result);
      setPlan(res?.data?.result);
      setLoader(false);
    }
  };
  

  return (
    <div>
      <div>{subscriptionTabs}</div>
    </div>
  );
}

export default ActiveSubscription;
