import React from 'react';
import { useEffect, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import PersonalDetails from './PersonalDetails';

import ProfilePopup from './ProfileViewPopup'

import { FaUsers } from 'react-icons/fa';
import { MdVerifiedUser } from 'react-icons/md';
import { IoWallet } from 'react-icons/io5';
import { LuSettings2 } from 'react-icons/lu';
import { IoMdHelpBuoy } from 'react-icons/io';
import { HiOutlineHome } from 'react-icons/hi2';
import { FiClock } from 'react-icons/fi';
import { RiMessage2Line } from 'react-icons/ri';

import { LuHistory } from "react-icons/lu";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { IoWalletOutline } from "react-icons/io5";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { LuLogOut } from "react-icons/lu";
import { logoutOnJwtExpire } from '../../utils/helperFunctions';


function UserProfile() {

    const [ViewProfileBlock, setViewProfileBlock] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    // mobile width useEffect
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
        }
        
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, []);


    function viewProfileSection(data) {
        setViewProfileBlock(!ViewProfileBlock);
        console.log(data);
    }


    const NavLinks = [
        {
            linkTo: 'appoinment',
            icon: <LuHistory className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: <FiClock className="text-2xl text-blue-500 m-auto" />,
            name: 'Appoinment History',
        },
        {
            linkTo: 'details',
            icon: <HiOutlineUserCircle className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: <RiMessage2Line className="text-2xl text-blue-500 m-auto" />,
            name: 'Personal Details',
        },
        {
            linkTo: 'payment',
            icon: <IoWalletOutline className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: <IoWallet className="text-2xl text-blue-500 text-coalBlack m-auto" />,
            name: 'Payment Method',
        },
        {
            linkTo: 'settings',
            icon: <LuSettings2 className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: '',
            name: 'Settings',
        },
        {
            linkTo: 'help',
            icon: <IoMdHelpCircleOutline  className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: '',
            name: 'Help Center',
        },
        {
            linkTo: 'delete',
            icon: <RiDeleteBinLine className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: '',
            name: 'Delete Account',
        },
        {
            linkTo: 'logout',
            icon: <LuLogOut className="text-2xl text-blue-500 text-inherit" />,
            mobIcon: '',
            name: 'Logout',
            onclick:logoutOnJwtExpire,
        },

    ];

    const desktopLinks = NavLinks.map((data, index) => {
        return (
            <>
                <div onClick={() => viewProfileSection(data.linkTo)} className="flex flex-row px-5 gap-5 justify-between mt-9 w-full text-lg text-center text-black whitespace-nowrap">
                <div className="flex gap-4">
                    <div className="p-2 bg-blue-200 rounded-full">{data.icon}</div>
                    <div className="grow my-auto">{data.name}</div>
                </div>
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d7f6ac1f896c01186f785806f3a9bab5bb5e1125bf01da0a0c183bca8bbd474?"
                className="shrink-0 my-auto w-6 aspect-square"
                />
                </div>
                
                
                {isMobile ? (
                    ViewProfileBlock && (
                        <ProfilePopup closeFunction={viewProfileSection} />
                    )
                ) : (
                    <div className="hidden md:block">
                        {ViewProfileBlock && (
                            <ProfilePopup closeFunction={viewProfileSection} />
                        )}
                    </div>
                )}

            </>
        );
    });

    return (        
        <AdminLayout>
            <div className="lg:flex lg:flex-row overflow-auto lg:pt-8 lg:pb-8 lg:pl-8 lg:gap-5 lg:bg-slate-50 lg:max-w-7xl lg:rounded-xl lg:w-[calc(100%-10rem)] lg:m-5 md:items-stretch">
                <div className="w-500 lg:border-r-2 border-solid border-gray-200 md:w-[500px]">
                    <div className="flex items-center gap-2  min-h-10 mb-2 xl:hidden sticky top-[-1px] py-3 bg-white z-10">
                        <HiOutlineArrowSmallLeft
                            className="text-base text-coalBlack p-1.5 w-9 h-9 rounded-full active:bg-blue-50 xl:hidden"
                            onClick={() => {
                            window.history.back();
                            }}
                        />
                        <p className="text-2xl font-medium text-coalBlack">Profile</p>
                    </div>

                    <div className="flex flex-col items-center gap-5">
                        <img
                            loading="lazy"
                            src="https://th.bing.com/th/id/OIP.j8yd8dJ5215WbgQ0NsLzuAHaNK?rs=1&pid=ImgDetMain"
                            className="object-cover w-20 h-20 rounded-full bg-blue-400"
                        />
                        <div className="mt-4 text-xl font-medium text-center text-black whitespace-nowrap">
                            Sanjay Kumar
                        </div>
                    </div>    

                    {desktopLinks}
                    
                </div>


                {/* desktop profile side-view section */}
                {/* <div id='desktop-profile' className="relative w-500 border-r-1 border-solid border-gray-300 md:w-[500px] hidden lg:block">
                    {desktopLinks}
                </div> */}


                <div className="relative w-500 border-r-1 border-solid border-gray-300 md:w-[500px] hidden lg:block">
                    <ProfilePopup closeFunction={viewProfileSection} />
                </div>


            </div>    
        </AdminLayout>

        
    );
  
}

export default UserProfile;





