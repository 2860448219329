import React, { createContext } from 'react';

export const AppContext = createContext();

export const ConvertToProjectContext = createContext();
export const BriefFormContext = createContext();
export const EditFormContext = createContext();
export const EditProjectContext = createContext();
export const DashContext = createContext();
export const SearchContext = React.createContext();
export const UserContext = createContext();
export const ProfileSliderContext = createContext();
export const walletContext = createContext();



// export default AppContext;
