import { useFormik } from 'formik';
import React, { useState } from 'react';
import { InputErrorMessage } from '../../components/errorMessages';
import SimpleButton from '../../components/common/buttons/SimpleButton/SimpleButton';
import { IoEyeOff } from 'react-icons/io5';
import { IoEye } from 'react-icons/io5';
import MandatoryLabel from '../../components/common/label';
import { ResetPasswordValidation } from '../../components/Validation';
import { ForgotPasswordChangepasswordApi } from '../../apis';
import toast from 'react-hot-toast';

function UpdatePassword({afterPasswordUpdate}) {
  
  const [loader, setLoader] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const formik2 = useFormik({
    initialValues: { newpassword: '', confirmpassword: '', userid: '' },
    validationSchema: ResetPasswordValidation,
    onSubmit: async (values) => {
      const { newpassword, confirmpassword, userid } = values;

      try {
        setLoader(true);
        const res = await ForgotPasswordChangepasswordApi({
          userId: localStorage.getItem('userId'),
          password: newpassword,
        });
        if (res.status === 200) {
          afterPasswordUpdate();
          toast.success(res.data.message);
        }
      } catch (error) {
        setLoader(false);
        const message =
          error.response.data.message || error.response.statusText;
        toast.error(message, { id: 'error' });
      } finally {
        setLoader(false);
      }
    },
  });
  function handelPasswordType() {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else if (passwordType == 'password') {
      setPasswordType('text');
    }
  }
  function handelConfirmPasswordType() {
    if (confirmPasswordType == 'text') {
      setConfirmPasswordType('password');
    } else if (confirmPasswordType == 'password') {
      setConfirmPasswordType('text');
    }
  }
  const handleChange2 = (e) => {
    e.preventDefault();
    formik2.handleChange(e);
  };
  return (
    <p>
      <form className="resetpassword max-w-md" onSubmit={formik2.handleSubmit}>
        <h2 className="baseText !font-medium max-w-md mb-4 !mt-10">
          Set the new password for your account so you can login and access all
          the features.
        </h2>
        <div className=" my-2 py-2 space-y-4">
          <div className="newPasswordField">
            <MandatoryLabel title="New Password" />
            <div className="relative">
              <input
                type={passwordType}
                name="newpassword"
                className={
                  Boolean(formik2.touched.newpassword) &&
                  Boolean(formik2.errors.newpassword)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="newpassword"
                placeholder="New Password "
                value={formik2.values.newpassword}
                onChange={handleChange2}
              />
              <div
                className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
                onClick={handelPasswordType}
              >
                {passwordType == 'password' ? (
                  <IoEyeOff />
                ) : passwordType == 'text' ? (
                  <IoEye />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <InputErrorMessage
              error={formik2.touched.newpassword && formik2.errors.newpassword}
            />
          </div>
          <div className="confirmPasswordField">
            <MandatoryLabel title="Confirm Password" />
            <div className="relative">
              <input
                type={confirmPasswordType}
                name="confirmpassword"
                className={
                  Boolean(formik2.touched.confirmpassword) &&
                  Boolean(formik2.errors.confirmpassword)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="confirmpassword"
                placeholder="confirm Password "
                value={formik2.values.confirmpassword}
                onChange={handleChange2}
              />
              <div
                className="absolute top-1/2 -translate-y-1/2 right-4 p-2 cursor-pointer"
                onClick={handelConfirmPasswordType}
              >
                {confirmPasswordType == 'password' ? (
                  <IoEyeOff />
                ) : confirmPasswordType == 'text' ? (
                  <IoEye />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <InputErrorMessage
              error={
                formik2.touched.confirmpassword &&
                formik2.errors.confirmpassword
              }
            />
          </div>
          <SimpleButton
            customClass={'bg-accent text-white rounded-lg w-full'}
            buttonType={'primary'}
            title={'Update Password'}
            onClickEvent={formik2.handleSubmit}
          />
        </div>
      </form>
    </p>
  );
}

export default UpdatePassword;
