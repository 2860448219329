// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --x-button-primary:#0F1035;
  --x-button-unfilledcolor:#DCF2F1;
}
@media (max-width:340px) {
  .simple-button{
    min-width: 125px !important;
  }
}
.simple-button {
  display: flex;
  font-size: 16px;
  height: 44px;
  min-width: 130px;
  align-items: center;
  justify-content: center;
  /* margin-left: 20px; */
  white-space: nowrap;
  /* width: 98%; */
  padding: 0 8px;
}
.simple-button-icon {
  width: 20px;
  margin-right: 6px;
}

.primary {
  background-color: var(--x-button-primary);
  color: white;
}
.primary-unfilled {
  background-color: transparent;
  /* border: 1px solid var(--x-button-primary); */
  /* color: var(--x-button-unfilledcolor) !important; */
}
.primary-unfilled > img {
  filter: invert(38%) sepia(60%) saturate(800%) hue-rotate(48deg) brightness(85%) contrast(104%);
}`, "",{"version":3,"sources":["webpack://./src/components/common/buttons/SimpleButton/simpleButton.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gCAAgC;AAClC;AACA;EACE;IACE,2BAA2B;EAC7B;AACF;AACA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;EACvB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;EACzC,YAAY;AACd;AACA;EACE,6BAA6B;EAC7B,+CAA+C;EAC/C,qDAAqD;AACvD;AACA;EACE,8FAA8F;AAChG","sourcesContent":[":root {\n  --x-button-primary:#0F1035;\n  --x-button-unfilledcolor:#DCF2F1;\n}\n@media (max-width:340px) {\n  .simple-button{\n    min-width: 125px !important;\n  }\n}\n.simple-button {\n  display: flex;\n  font-size: 16px;\n  height: 44px;\n  min-width: 130px;\n  align-items: center;\n  justify-content: center;\n  /* margin-left: 20px; */\n  white-space: nowrap;\n  /* width: 98%; */\n  padding: 0 8px;\n}\n.simple-button-icon {\n  width: 20px;\n  margin-right: 6px;\n}\n\n.primary {\n  background-color: var(--x-button-primary);\n  color: white;\n}\n.primary-unfilled {\n  background-color: transparent;\n  /* border: 1px solid var(--x-button-primary); */\n  /* color: var(--x-button-unfilledcolor) !important; */\n}\n.primary-unfilled > img {\n  filter: invert(38%) sepia(60%) saturate(800%) hue-rotate(48deg) brightness(85%) contrast(104%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
