import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap, useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import axios from 'axios';

// Fix leaflet's default icon issue in react
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/images/marker-shadow.png'
});

const SearchBar = ({ onSearch, onSuggestionsActive }) => {
  const [input, setInput] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchSuggestions = async (query) => {
    if (query.trim() !== '') {
      setLoading(true);
      setError('');
      try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${query}&format=json&addressdetails=1&limit=5`);
        setSuggestions(response.data);
        onSuggestionsActive(true);
        setLoading(false);
        if (response.data.length === 0) {
          setError('No location found');
        }
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setLoading(false);
        setError('Error fetching suggestions');
      }
    } else {
      setSuggestions([]);
      onSuggestionsActive(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    fetchSuggestions(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion.display_name);
    setSuggestions([]);
    onSearch(suggestion.display_name);
    onSuggestionsActive(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (input.trim() !== '') {
      onSearch(input);
      setSuggestions([]);
      onSuggestionsActive(false);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <form
        onSubmit={handleSearch}
        style={{
          position: 'absolute',
          top: '10px',
          right: '1%',
          zIndex: 1000,
          display: 'flex',
        }}>
        <input
          type="text"
          placeholder="Search for a location"
          value={input}
          onChange={handleInputChange}
          onFocus={() => onSuggestionsActive(true)}
          onBlur={() => onSuggestionsActive(false)}
          style={{
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px 0 0 4px',
            outline: 'none',
          }}
        />
        <button
          type="submit"
          style={{
            padding: '10px',
            border: '1px solid #ccc',
            borderLeft: 'none',
            borderRadius: '0 4px 4px 0',
            backgroundColor: '#007bff',
            color: 'white',
            cursor: 'pointer',
          }}>
          Search
        </button>
      </form>
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: '45px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1001,
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: 'calc(100% - 22px)',
          }}>
          Processing...
        </div>
      )}
      {error && !loading && (
        <div
          style={{
            position: 'absolute',
            top: '45px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1001,
            backgroundColor: 'white',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            width: 'calc(100% - 22px)',
          }}>
          {error}
        </div>
      )}
      {suggestions.length > 0 && (
        <ul
          style={{
            position: 'absolute',
            top: '45px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1001,
            backgroundColor: 'white',
            listStyle: 'none',
            padding: '0',
            margin: '0',
            width: 'calc(100% - 22px)',
            border: '1px solid #ccc',
            borderRadius: '4px',
            maxHeight: '200px',
            overflowY: 'auto',
          }}>
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.place_id}
              onMouseDown={() => handleSuggestionClick(suggestion)} // use onMouseDown to handle click before blur
              style={{
                padding: '10px',
                borderBottom: '1px solid #ccc',
                cursor: 'pointer',
              }}>
              {suggestion.display_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const MapComponent = ({ address, handleChange }) => {
  const defaultPosition = [13.0827, 80.2707]; // Chennai coordinates
  const [position, setPosition] = useState(defaultPosition);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const map = useMap();

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (address) {
        try {
          const response = await axios.get(`https://nominatim.openstreetmap.org/search?q=${address}&format=json&addressdetails=1&limit=1`);
          if (response.data.length > 0) {
            const { lat, lon, display_name } = response.data[0];
            const newPosition = [parseFloat(lat), parseFloat(lon)];
            setPosition(newPosition);
            map.setView(newPosition, 13);
            console.log(`Latitude: ${lat}, Longitude: ${lon}, Address: ${display_name}`);
          } else {
            console.error("Location not found", response);
          }
        } catch (error) {
          console.error("Error fetching location data:", error);
        }
      }
    };
    fetchCoordinates();
  }, [address, map]);

  const handleMarkerDragEnd = async (event) => {
    const marker = event.target;
    const newPosition = marker.getLatLng();
    setPosition([newPosition.lat, newPosition.lng]);

    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?lat=${newPosition.lat}&lon=${newPosition.lng}&format=json`);
      const newAddress = response.data.display_name;
      handleChange(newAddress);
      console.log(`Latitude: ${newPosition.lat}, Longitude: ${newPosition.lng}, Address: ${newAddress}`);
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  useMapEvent('dragstart', () => {
    if (suggestionsActive) {
      map.dragging.disable();
    }
  });

  useMapEvent('dragend', () => {
    if (suggestionsActive) {
      map.dragging.enable();
    }
  });

  useMapEvent('movestart', () => {
    if (suggestionsActive) {
      map.scrollWheelZoom.disable();
    }
  });

  useMapEvent('moveend', () => {
    if (suggestionsActive) {
      map.scrollWheelZoom.enable();
    }
  });

  return (
    <>
      <SearchBar onSearch={handleChange} onSuggestionsActive={setSuggestionsActive} />
      <Marker
        position={position}
        draggable={true}
        eventHandlers={{
          dragend: handleMarkerDragEnd,
        }}
      ></Marker>
    </>
  );
};

const MapWrapper = (props) => {
  return (
    <MapContainer center={[13.0827, 80.2707]} zoom={13} style={{ height: '500px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <MapComponent {...props} />
    </MapContainer>
  );
};

export default MapWrapper;
