import React, { useState } from 'react';
import CompletedConsultation from '../../components/MessageNotification/CompletedConsultation';
import NoShowConsultation from '../../components/MessageNotification/NoShowConsultation';
import ConsultationViewRequest from '../../components/ConsultationCard/ConsultationViewRequest';

function MonthlyEarnings({ data }) {
  console.log(data);

  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  function handelViewRequestBlock(data) {
    setCurrentRequest(data);
    setViewRequestBlock(!ViewRequestBlock);
    console.log('consultdata', data?._id);
  }
  if (data == undefined) {
    return <p className='py-10 text-center px-4 baseText'>No consultation found</p>;
  }
  return (
    <>
      {data?.TransactionRecord?.map((data) => (
          data.consultation_id[0].status === 'completed' && <CompletedConsultation
          onclick={() => handelViewRequestBlock(data?.consultation_id[0])}
          data={data}
        />
      ))}

      {ViewRequestBlock && (
        <ConsultationViewRequest
          closeFunction={handelViewRequestBlock}
          currentdata={currentRequest?._id}
        />
      )}
    </>
  );
}

export default MonthlyEarnings;
