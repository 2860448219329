import moment from "moment";
import { SendingStatus } from '@sendbird/chat/message';
import { Check, CheckAll } from 'react-bootstrap-icons';

export const GetLastMessageTime = (createdAt) => {
    if (typeof createdAt === 'undefined' || createdAt === '') {
        return '';
    }
    const current = moment();
    const m = moment(parseInt(createdAt));
    const diff = m.diff(current, 'days');
    if (diff === 0) { // same day
        return m.format('hh:mm A')
    }

    return m.format('MMM DD');
};

export const GetTimeSent = (createdAt) => {
    const m = moment(parseInt(createdAt));
    return m.format('hh:mm A');
};


export const GetReadStatus = (sendingStatus, unreadMemberCount) => {
    if (sendingStatus === SendingStatus.SUCCEEDED && unreadMemberCount === 0) {
        return (<CheckAll fontSize={16} fontWeight={600} />);
    } else if(sendingStatus === SendingStatus.SUCCEEDED) {
        return (<Check fontSize={16} fontWeight={600} />);
    }

    return (<></>);
}