import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';


function PersonalDetails({desktopLinks}) {

    return (
        <AdminLayout>
            <Formik
                initialValues={{
                    name: 'Sanjay Kumar',
                    contactNumber: '+91 764543356',
                    dob: 'DD-MM-YYYY',
                    location: '',
                }}
                onSubmit={values => {
                    // submit form values to servers
                }}
            >
                {({ isSubmitting }) => (

                    <Form action="#" className="lg:flex lg:flex-row lg:pt-8 lg:pb-8 lg:pl-8 lg:gap-5 lg:bg-slate-50 lg:max-w-7xl lg:rounded-xl lg:w-[calc(100%-10rem)] lg:m-5 md:items-stretch">
                
                        <div className="w-500 lg:border-r-2 border-solid border-gray-200 md:w-[500px]">

                            <div className="flex items-center gap-2  min-h-10 mb-2 xl:hidden sticky top-[-1px] py-3 bg-white z-10">
                                <HiOutlineArrowSmallLeft
                                    className="text-base text-coalBlack p-1.5 w-9 h-9 rounded-full active:bg-blue-50 xl:hidden"
                                    onClick={() => {
                                    window.history.back();
                                    }}
                                />
                                <p className="text-2xl font-medium text-coalBlack">Profile</p>
                            </div>

                            <div className="flex flex-col items-center gap-5">
                                <img
                                    loading="lazy"
                                    src="https://th.bing.com/th/id/OIP.j8yd8dJ5215WbgQ0NsLzuAHaNK?rs=1&pid=ImgDetMain"
                                    className="object-cover w-20 h-20 rounded-full bg-blue-400"
                                />
                                <div className="mt-4 text-xl font-medium text-center text-black whitespace-nowrap">
                                    Sanjay Kumar
                                </div>
                            </div>    

                            {desktopLinks}
                            
                        </div>

                        {/* <div className="w-500 border-r-1 border-solid border-gray-300 md:w-[500px] hidden lg:block">
                            <div className="flex flex-col font-medium max-w-[455px]">
                                <div className="flex gap-4 px-4 py-4 text-2xl text-black">
                                    <div className="flex-auto">Personal Details</div>
                                </div>
                                <div className="flex flex-col items-start pl-20 mt-6 w-full text-base text-slate-500">
                                    
                                    <img 
                                        loading="lazy" 
                                        src="https://th.bing.com/th/id/OIP.j8yd8dJ5215WbgQ0NsLzuAHaNK?rs=1&pid=ImgDetMain"
                                        className="w-20 h-20 rounded-full bg-blue-400 object-cover"
                                    />

                                    <div className="mt-3.5 tracking-tight">Edit your profile image</div>
                                    <div className="mt-11 text-lg text-zinc-800">Personal information</div>
                                    
                                    
                                    <div className="mt-5 text-sm text-black">Name</div>
                                    <Field name="name" className="flex w-full gap-5 px-5 py-5 mt-4 bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
                                    <div className="mt-4 text-sm text-black">Contact Number</div>
                                    <Field name="contactNumber" className="flex w-full gap-5 px-5 py-5 mt-4 bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
                                    <div className="mt-3.5 text-sm text-black">Date of birth</div>
                                    <Field name="dob" className="flex w-full gap-5 px-5 py-5 mt-4 whitespace-nowrap bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
                                    <div className="mt-3.5 text-sm text-black">Location</div>
                                    <Field name="location" className="justify-center items-start w-full px-6 py-5 mt-4 bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
                                    <button type="submit" disabled={isSubmitting} className="justify-center w-full items-center px-16 py-4 mt-6 text-2xl text-white whitespace-nowrap bg-blue-700 rounded-md">
                                        Update
                                    </button>
                                </div>
                            </div>
                            
                        </div> */}
                    </Form>
                )}
            </Formik>
        </AdminLayout>
    );

}


export default PersonalDetails;

