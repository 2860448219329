import React, { useState } from 'react';
import { useFormik } from 'formik';
import {
  HospitalRegistrationStepOne,
  HospitalRegistrationStepTwo,
} from '../Validation/index.js';
import RegisterFormFirst from './RegisterationForm1.js';
import RegisterFormSecond from './RegisterationForm2.js';
import MandatoryFieldsNotice from './RequiredFieldLablecon.js';
import StepsIndicator from './FormProgress.js';
import RegisterationHeader from './TopHeader.js';
import WaitingScreen from './WaitingScreen.js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { NewregisteratoinApi, loginApi } from '../../apis/index.js';
import toast from 'react-hot-toast';

function RegisterForm({ nextScreen, prevScreen }) {
  const [loader, setLoader] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [preventFirstNextFormVal, setPreventFirstNextFormVal] = useState(false);

  const handlePrevious = () => {
    if (step > 1) {
      setFormValues(formik.values);
      setStep(step - 1);
    } else {
      prevScreen();
    }
  };

  const formik = useFormik({
    initialValues: {
      hosName: '',
      speciality: '',
      address: '',
      latitude:'',
      longitude:'',
      representativePhoneNumber: '',
      representativeEmail: '',

    },
    validationSchema: HospitalRegistrationStepOne,
    onSubmit: async (values) => {
      console.log(values);
      setStep(2);

    },
  });
  const formik2 = useFormik({
    initialValues: {
      RegistrationNumber: '',
      capacity: '',
      hospitalLicense: undefined,
      profile: '',
      cover: '',
      licencename: '',
      licencesize: ''
    },
    validationSchema: HospitalRegistrationStepTwo,
    onSubmit: async (values) => {

      // setStep(2);
      const { hosName, speciality, address, representativeEmail, representativePhoneNumber,latitude,longitude } = formik.values;
      const { RegistrationNumber, capacity, hospitalLicense, profile, cover, licencename, licencesize } = values;
      const phoneNumber = parsePhoneNumberFromString(representativePhoneNumber, 'IN');

      // Extract the country code and national number
      const countryCode = phoneNumber.countryCallingCode;
      const nationalNumber = phoneNumber.nationalNumber;
      console.log(countryCode, nationalNumber);
      try {
        setLoader(true);
        const res = await NewregisteratoinApi(

          {
            'isActive': true,
            'status': 'active',
            'userId': localStorage.getItem('userId'),
            'hospitalName': hosName,
            'speciality': speciality,
            'address': address,
            'refCountryCode': `+${countryCode}`,
            'refPhoneNo': nationalNumber,
            'regNumber': RegistrationNumber,
            'hospitalCapacity': capacity,
            'coverPic': cover,
            'profilePic': profile,
            'documents': [
              {
                'type': 'Hospital Licence',
                'id': '',
                'fileName': licencename,
                'filePath': hospitalLicense,
                'fileSize': licencesize
              }
            ],
            'isApproved': false,
            'lat':latitude,
            'long':longitude,

          }


        );
        if (res.status === 200) {
          console.log(res.data.message);
          toast.success(res.data.message);
          setFormSubmit(true);

        }
      } catch (error) {
        setLoader(false);
        const message =
          error?.response?.data?.message || error?.response?.statusText;
        toast.error(message, { id: 'error' });
      }
      finally {
        setLoader(false);
      }



    },
  });



  return (
    <section className="lg:w-1/2 relative lg:py-10 ">
      <div className="p-4  mx-auto scrollbar md:max-w-[400px]  lg:max-h-[96vh] lg:overflow-y-auto">
        <RegisterationHeader heading="hospital Registration" handlePrevious={handlePrevious} />
        <StepsIndicator step={step} />
        <MandatoryFieldsNotice />

        {step === 1 && (
          <RegisterFormFirst formik={formik} loader={loader} setLoader={setLoader} />
        )}
        {step === 2 && (
          <>
            <RegisterFormSecond formik={formik2} loader={loader} setLoader={setLoader} />
          </>
        )}

        {formSubmit && (
          <WaitingScreen nextScreen={nextScreen} loader={loader} setLoader={setLoader} />
        )}
      </div>
    </section>
  );
}
export default RegisterForm;