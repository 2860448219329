import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { IoVideocam } from 'react-icons/io5';
import { FaTags } from 'react-icons/fa';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { InputErrorMessage } from '../errorMessages';
import { ConsultationRequest } from '../Validation';
import { HiOutlinePlus } from 'react-icons/hi2';
import { HiOutlineX } from 'react-icons/hi';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { EditProfileLoader } from '../common/loader';
import { GrNotes } from 'react-icons/gr';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { useDropzone } from 'react-dropzone';
import { CloudArrowUpFill, X } from 'react-bootstrap-icons';
import DatePicker from 'react-date-picker';
import moment from 'moment';
import {
  fileUploadApi,
  getCreditBasePrice,
  getSpecialization,
  getUserApi,
  getdoctorslistAPI,
  insertconsultationAPI,
  updateconsultationAPI,
} from '../../apis';
import Select from 'react-select';
import RecordSelector from './RecordsSelect';
import ProgressBar from 'react-bootstrap/esm/ProgressBar';
import { IoClose } from 'react-icons/io5';
import { AppContext } from '../../appContext';
import { useNavigate } from 'react-router-dom';

function PatientDetails({ formik, uploadedFiles, setUploadedFiles, loader }) {
  // const [loader, setLoader] = useState(false);
  const { loggedInUser } = useContext(AppContext);
  const [remainingChars, setRemainingChars] = useState(100);
  const [remainingCharsdoc, setRemainingCharsdoc] = useState(1000);
  const [remainingmedicalailment, setRemainingmedicalailment] = useState(1000);
  const [inputValue, setInputValue] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState([]);
  const [filename, setfileName] = useState([]);
  const [filesize, setfilesize] = useState([]);
  const [fileiserror, setfileiserror] = useState([]);
  const [isfile, setisfile] = useState(false);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const addTag = () => {
    const newTag = inputValue.trim();
    if (newTag) {
      const newMedicalTags = [...formik.values.medicalTags, newTag]; // Get current medicalTags from Formik values
      formik.setFieldValue('medicalTags', newMedicalTags); // Update medicalTags in Formik
      setInputValue('');
    }
  };

  const removeTag = (tagToRemove) => {
    const newMedicalTags = formik.values.medicalTags.filter(
      (tag) => tag !== tagToRemove
    ); // Get current medicalTags from Formik values
    formik.setFieldValue('medicalTags', newMedicalTags); // Update medicalTags in Formik
  };
  const handleChangeDatePicker = (name, value) => {
    formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'));
  };
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        console.log(formik.values.Reporttype);
        // Process each accepted file
        acceptedFiles.forEach((file, index) => {
          fileupload(
            file,
            index,
            acceptedFiles.length,
            formik.values.Reporttype
          );
        });
      }
    },
    [formik.values.Reporttype]
  );
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  const onUploadProgress = (progressEvent, file, id) => {
    const loaded = progressEvent.loaded;
    const total = progressEvent.total;

    const percentage = Math.round((loaded / total) * 100);

    setUploadedFiles((prevUploadedFiles) => {
      return prevUploadedFiles.map((uploadedFile) => {
        if (uploadedFile.id === id) {
          return {
            ...uploadedFile,
            updatedProgress: percentage,
          };
        }
        return uploadedFile;
      });
    });
  };

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const fileupload = async (file, index, totalFiles, recordtype) => {
    const fileId = generateUniqueId(); // Assuming you have a function to generate a unique ID

    try {
      setisfile(true);
      setfileiserror((prevFileName) => {
        const updatedStatus = [...prevFileName];
        updatedStatus[index] = false;
        return updatedStatus;
      });
      console.log(recordtype);
      const fileDetails = {
        type: recordtype,
        id: fileId,
        fileName: file.name,
        filePath: '',
        fileSize: formatFileSize(file.size),
        updatedProgress: 0,
        isuploading: true, // Set isuploading to true initially
      };

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        fileDetails,
      ]);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', fileId);

      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file, fileId)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        setfileName((prevFileName) => {
          const updatedFileName = [...prevFileName];
          updatedFileName[index] = file.name;
          return updatedFileName;
        });

        setfilesize((prevFileSize) => {
          const updatedFileSize = [...prevFileSize];
          updatedFileSize[index] = formatFileSize(file.size);
          return updatedFileSize;
        });
        setUploadedFiles((prevUploadedFiles) => {
          return prevUploadedFiles.map((uploadedFile) => {
            if (uploadedFile.id === fileId) {
              return {
                ...uploadedFile,
                type: formik.values.Reporttype,
                isuploading: false,
                filePath: uploadResponse.data.filePath, // Assuming uploadResponse has filePath
              };
            }
            return uploadedFile;
          });
        });
      } else {
        setfileiserror((prevFileName) => {
          const updatedStatus = [...prevFileName];
          updatedStatus[index] = true;
          return updatedStatus;
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setfileiserror((prevFileName) => {
        const updatedStatus = [...prevFileName];
        updatedStatus[index] = true;
        return updatedStatus;
      });
    } finally {
      if (index === totalFiles - 1) {
        setisfile(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const removeFile = (indexToRemove) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      addTag();
    }
  };
  const handleRecordSelect = async (recordType) => {
    formik.setFieldValue('Reporttype', recordType);
  };
  const handleChangedescription = (e, setstateval, limit) => {
    const inputText = e.target.value;
    const remaining = limit - inputText.length;
    if (remaining >= 0) {
      setstateval(remaining);
    } else {
      setstateval(0);
    }
  };
  const inputRef = useRef(null);

  const openDatePicker = () => {
    if (
      'showPicker' in inputRef.current &&
      typeof inputRef.current.showPicker === 'function'
    ) {
      inputRef.current.showPicker();
    }
  };
  return (
    <form
      className="NewRequestForm space-y-3.5 mt-6 text-coalBlack text-base bg-white"
      onSubmit={handleSubmit}
    >
      <div className="relative doctorName">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Referral Doctor
        </p>
        <input
          type="text"
          id="ReferralDoctor"
          name="ReferralDoctor"
          value={formik.values.ReferralDoctor}
          onChange={formik.handleChange}
          className={
            Boolean(formik.touched.ReferralDoctor) &&
            Boolean(formik.errors.ReferralDoctor)
              ? 'form-control border-danger'
              : 'form-control'
          }
          placeholder="Enter Referral Doctor Name"
        />
        <InputErrorMessage
          error={formik.touched.ReferralDoctor && formik.errors.ReferralDoctor}
        />
      </div>

      <div className="relative doctorName">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Referral Doctor Phone no
        </p>
        <div className="flex gap-1 items-center">
          <input
            type="text"
            readOnly
            className="form-control cursor-not-allowed max-w-12 p-2 inline-flex justify-center items-center"
            value={'+91'}
          />
          <input
            type="text"
            name="ReferralDoctorPhone"
            className={
              Boolean(formik.touched.ReferralDoctorPhone) &&
              Boolean(formik.errors.ReferralDoctorPhone)
                ? 'form-control border-danger transparent-input-border'
                : 'form-control transparent-input-border'
            }
            id="ReferralDoctorPhone"
            placeholder="Enter 10 digit mobile No"
            value={formik.values.ReferralDoctorPhone}
            onChange={handleChange}
          />
        </div>

        <InputErrorMessage
          error={
            formik.touched.ReferralDoctorPhone &&
            formik.errors.ReferralDoctorPhone
          }
        />
      </div>
      <div className="relative requestDescription">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Request Description
        </p>
        <textarea
          type="text"
          name="RequestDescription"
          className={
            Boolean(formik.touched.RequestDescription) &&
            Boolean(formik.errors.RequestDescription)
              ? 'form-control border-danger transparent-input-border'
              : 'form-control transparent-input-border'
          }
          id="RequestDescription"
          placeholder="Enter description"
          value={formik.values.RequestDescription}
          onChange={(e) => {
            handleChangedescription(e, setRemainingChars, 100);
            formik.handleChange(e);
          }}
        ></textarea>

        <InputErrorMessage
          error={
            formik.touched.RequestDescription &&
            formik.errors.RequestDescription
          }
        />
      </div>

      <div className="relative doctorName">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Requested Date
        </p>
        {/* <input
        ref={inputRef}
        type="date"
        className={`${
          Boolean(formik.touched?.requestedDate) &&
          Boolean(formik.errors?.requestedDate)
            ? ' form-control datePlaceholder border-danger transparent-input-border'
            : 'form-control datePlaceholder transparent-input-border'
        } form-control chatInput datePlaceholder`}
        name="requestedDate"
        value={formik.values?.requestedDate}
        onChange={handleChange}
        onClick={openDatePicker} // Use onClick to trigger the date picker
      />

        <InputErrorMessage
          error={
            formik.touched.requestedDate &&
            (formik.errors.requestedDate ===
            'requestedDate must be a `date` type, but the final value was: `Invalid Date`.'
              ? 'Invalid Date'
              : formik.errors.requestedDate)
          }
        /> */}
        <div className="relative">
          <input
            ref={inputRef}
            type="date"
            className={`${
              Boolean(formik.touched?.requestedDate) &&
              Boolean(formik.errors?.requestedDate)
                ? ' form-control datePlaceholder border-danger transparent-input-border'
                : 'form-control datePlaceholder transparent-input-border'
            } form-control chatInput datePlaceholder`}
            name="requestedDate"
            value={formik.values?.requestedDate}
            onChange={handleChange}
            onClick={openDatePicker}
          />
          <InputErrorMessage
            error={
              formik.touched.requestedDate &&
              (formik.errors.requestedDate ===
              'requestedDate must be a `date` type, but the final value was: `Invalid Date`.'
                ? 'Invalid Date'
                : formik.errors.requestedDate)
            }
          />
        </div>
      </div>

      <p className="text-lg font-medium text-coalBlack ">Patient Details</p>
      <div className="relative Age">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Age
        </p>
        <input
          type="number"
          name="Age"
          className={
            Boolean(formik.touched.Age) && Boolean(formik.errors.Age)
              ? 'form-control border-danger transparent-input-border'
              : 'form-control transparent-input-border'
          }
          id="Age"
          placeholder="Age in years"
          value={formik.values.Age}
          onChange={handleChange}
        />
        <InputErrorMessage error={formik.touched.Age && formik.errors.Age} />
      </div>

      <div className="gender">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Gender
        </p>
        <div className="relative">
          <div className="flex flex-wrap gap-3">
            <div className="flex items-center gap-1">
              <input
                type="radio"
                name="gender"
                id="male"
                value="male"
                checked={formik.values.gender === 'male'}
                onChange={handleChange}
              />
              <label htmlFor="male" className="select-none">
                Male
              </label>
            </div>
            <div className="flex items-center gap-1">
              <input
                type="radio"
                name="gender"
                id="female"
                value="female"
                checked={formik.values.gender === 'female'}
                onChange={handleChange}
              />
              <label htmlFor="female" className="select-none">
                Female
              </label>
            </div>
            <div className="flex items-center gap-1">
              <input
                type="radio"
                name="gender"
                id="others"
                value="others"
                checked={formik.values.gender === 'others'}
                onChange={handleChange}
              />
              <label htmlFor="others" className="select-none">
                others
              </label>
            </div>
          </div>
          <InputErrorMessage
            error={formik.touched.gender && formik.errors.gender}
          />
        </div>
      </div>
      {/* <div className="relative space-y-2.5 text-base font-medium consultationPriority">
        <p className="text-coalBlack capitalize text-sm">
          consultation Priority
        </p>
        <div className="bg-white p-1 rounded-2xl flex items-center justify-evenly shadow-blue_dropshadow">
          <input
            type="radio"
            name="consultationPriority"
            id="urgent"
            value="urgent"
            onChange={handleChange}
            checked={formik.values.consultationPriority === 'urgent'}
            hidden
          />
          <label htmlFor="urgent" className="w-full cursor-pointer">
            <div
              className={`capitalize px-3 py-2 rounded-xl flex-grow text-center ${
                formik.values.consultationPriority === 'urgent'
                  ? 'bg-[#FC5555] text-white'
                  : 'text-gray-400'
              }`}
            >
              Urgent
            </div>
          </label>
          <input
            type="radio"
            name="consultationPriority"
            id="regular"
            value="regular"
            onChange={handleChange}
            checked={formik.values.consultationPriority === 'regular'}
            hidden
          />
          <label htmlFor="regular" className="w-full cursor-pointer">
            <div
              className={`capitalize px-3 py-2 rounded-xl flex-grow text-center ${
                formik.values.consultationPriority === 'regular'
                  ? 'bg-green-400 text-white'
                  : 'text-gray-400'
              }`}
            >
              Routine
            </div>
          </label>
        </div>
        <InputErrorMessage
          error={
            formik.touched.consultationPriority &&
            formik.errors.consultationPriority
          }
        />
      </div> */}
      <div className="relative DoctorsDescription">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Doctors Description
        </p>
        <textarea
          type="text"
          name="DoctorsDescription"
          className={
            Boolean(formik.touched.DoctorsDescription) &&
            Boolean(formik.errors.DoctorsDescription)
              ? 'form-control border-danger transparent-input-border'
              : 'form-control transparent-input-border '
          }
          id="DoctorsDescription"
          placeholder="Enter Doctor description"
          value={formik.values.DoctorsDescription}
          onChange={(e) => {
            handleChangedescription(e, setRemainingCharsdoc, 1000);
            formik.handleChange(e);
          }}
        ></textarea>
        <div className="text-sm font-light text-gray-500 text-right">
          {remainingCharsdoc} characters left
        </div>
        <InputErrorMessage
          error={
            formik.touched.DoctorsDescription &&
            formik.errors.DoctorsDescription
          }
        />
      </div>
      <div className="relative MedicalTag">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Medical Tags
        </p>

        <div>
          <div className="relative">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleInputKeyDown}
              placeholder="Add new medical Tag"
              className="form-control"
            />
            {inputValue && (
              <div
                className="absolute top-1  right-1 w-14 h-9 rounded-lg bg-sky-100 flex justify-center items-center cursor-pointer"
                onClick={addTag}
              >
                <span className="text-gray-400 text-lg cursor-pointer">
                  <IoClose className="font-normal rotate-45 text-accent" />
                </span>
              </div>
            )}
          </div>
          <div className="my-2 flex flex-wrap gap-2">
            {formik.values.medicalTags?.map((tag, index) => (
              <div
                key={index}
                className="px-4 py-2 inline-flex rounded-full text-coalBlack bg-gray-100 capitalize"
              >
                {tag}
                <button
                  type="button"
                  className="ml-2 text-coalBlack text-xl"
                  onClick={() => removeTag(tag)}
                >
                  <IoClose className="font-normal" />
                </button>
              </div>
            ))}
          </div>
        </div>
        <InputErrorMessage
          error={formik.touched.medicalTags && formik.errors.medicalTags}
        />
      </div>

      <div className="addRecords">
        <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
          Add Records
        </p>
        <div className="flex flex-wrap gap-2 items-center mb-8 justify-start">
          <div className="relative">
            <div className="grid grid-cols-3 gap-4 my-4">
              <div
                className="w-[100px] h-32 rounded-xl bg-sky-100 p-2 flex flex-col items-center justify-center cursor-pointer"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <HiOutlinePlus className="w-8 h-8 text-accent mx-auto" />
                <p className="text-sm text-center text-accent">
                  Add more images
                </p>
              </div>
              {uploadedFiles?.map((file, index) => (
                <div>
                  {file.isuploading ? (
                    <>
                      <div className="relative">
                        <div className="w-[100px] bg-[#d5f3fa] h-32 border border-gray-200 rounded-lg overflow-hidden flex flex-col items-end justify-center">
                          <div className="h-32 flex  items-center justify-center w-[100px]">
                            <CloudArrowUpFill size={44} color="#1648CE" />
                          </div>

                          <div className="w-[90%] h-[5px] my-3 mx-1">
                            <ProgressBar now={file.updatedProgress} />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="relative">
                        <div className="w-[100px] h-32 border border-gray-200 rounded-lg overflow-hidden">
                          <img
                            src={file.filePath}
                            className="w-full h-full object-cover"
                            alt={file.fileName}
                          />
                        </div>
                        <button
                          type="button"
                          className="absolute top-1 right-1 bg-white rounded-xl border border-gray-200  cursor-pointer"
                          onClick={() => removeFile(index)} // Assuming removeFile is a function to remove the file from the array
                        >
                          <X className="text-gray-600" size={18} />{' '}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="TypesOfDocument space-y-2.5">
        <p className="text-sm font-medium text-coalBlack  capitalize">
          Types of Records
        </p>
        <RecordSelector
          onSelect={handleRecordSelect}
          defaultvalue={formik.values.Reporttype}
        />
      </div>
      <div className="py-10 submitBtn pb-24 lg:hidden">
        <button
          className="btn btn_primary_black text-white w-full rounded-xl"
          type={!loader ? 'submit' : 'button'}
        >
          {!loader ? (
            'Create Request'
          ) : (
            <div className="text-xs">
              <EditProfileLoader />
            </div>
          )}
        </button>
      </div>
    </form>
  );
}

export default PatientDetails;
