// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --wy-theme-color: #1648ce;
  /* --wy-border-radius-top-right:50px; */
}

`, "",{"version":3,"sources":["webpack://./src/components/oddsChat/component/weavyStyle..css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uCAAuC;AACzC","sourcesContent":[":root {\n  --wy-theme-color: #1648ce;\n  /* --wy-border-radius-top-right:50px; */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
