import React, { useState } from 'react';
import { InputErrorMessage } from '../../components/errorMessages/index.js'; // Adjust import paths as necessary
import { EditProfileLoader } from '../loader/loader.js';
import PhoneInput from 'react-phone-number-input';
import MapComponent from '../MapComponent/index.js';

function RegisterFormFirst({ formik, loader, setLoader }) {
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  return (
    <form onSubmit={formik.handleSubmit} className='pb-6'>
      <section className="PersonalDetails space-y-4 mt-6 p-4 shadow-md rounded-xl md:shadow-none">
        <div className="hosName">
          <h3 className="text-lg capitalize font-medium mb-4">
            hospital Details
          </h3>
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Hospital Name
          </p>
          <div className="relative">
            <input
              type="text"
              name="hosName"
              className={
                Boolean(formik.touched.hosName) &&
                  Boolean(formik.errors.hosName)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="hosName"
              placeholder="Apollo multispecialty hospital"
              value={formik.values.hosName}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.hosName && formik.errors.hosName}
            />
          </div>
        </div>
        <div className="speciality">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            speciality
          </p>
          <div className="relative">
            <input
              type="text"
              name="speciality"
              className={
                Boolean(formik.touched.speciality) &&
                  Boolean(formik.errors.speciality)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="speciality"
              placeholder="Orthopedic Surgery"
              value={formik.values.speciality}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={
                formik.touched.speciality && formik.errors.speciality
              }
            />
          </div>
        </div>
        <div className="hosAddress">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Address
          </p>
          <div className="relative">
            <input
              type="text"
              name="address"
              className={
                Boolean(formik.touched.address) &&
                  Boolean(formik.errors.address)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="address"
              placeholder="No.1, Arunachalam Road, Saligramam, "
              value={formik.values.address}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.address && formik.errors.address}
            />
          </div>
        </div>
        <div className="text-sm text-navLink">
          Note:
          <p className="hidden lg:block">Right-click the desired location and choose "What's here?" to see the coordinates.</p>
          <p className="block lg:hidden">Tap and hold on the desired location, then check the coordinates displayed at the bottom of the screen.</p>
        </div>

        <div className="latitude">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Latitude
          </p>
          <div className="relative">
            <input
              type="text"
              name="latitude"
              className={
                Boolean(formik.touched.latitude) &&
                  Boolean(formik.errors.latitude)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="latitude"
              placeholder="30.123456"
              value={formik.values.latitude}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.latitude && formik.errors.latitude}
            />
          </div>
        </div>
        <div className="longitude">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Longitude
          </p>
          <div className="relative">
            <input
              type="text"
              name="longitude"
              className={
                Boolean(formik.touched.longitude) &&
                  Boolean(formik.errors.longitude)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="longitude"
              placeholder="40.123456"
              value={formik.values.longitude}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={formik.touched.longitude && formik.errors.longitude}
            />
          </div>
        </div>


        <div className="representativePhoneNumber">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Representative phone number
          </p>
          <div className="relative">
            <div className="flex gap-1 items-center">
              <input
                type="text"
                readOnly
                className="form-control cursor-not-allowed max-w-12 p-2 inline-flex justify-center items-center"
                value={'+91'}
              />
              <input
                type="text"
                name="representativePhoneNumber"
                className={
                  Boolean(formik.touched.representativePhoneNumber) &&
                    Boolean(formik.errors.representativePhoneNumber)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="representativePhoneNumber"
                placeholder="Enter 10 digit mobile No"
                value={formik.values.representativePhoneNumber}
                onChange={handleChange}
              />
            </div>
            <InputErrorMessage
              error={formik.touched.representativePhoneNumber && formik.errors.representativePhoneNumber}
            />
          </div>
        </div>
        <div className="representativeEmail">
          <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
            Representative email
          </p>
          <div className="relative">
            <input
              type="email"
              name="representativeEmail"
              className={
                Boolean(formik.touched.representativeEmail) &&
                  Boolean(formik.errors.representativeEmail)
                  ? 'form-control border-danger transparent-input-border'
                  : 'form-control transparent-input-border'
              }
              id="representativeEmail"
              placeholder="sanjaykumar123@gmail.com"
              value={formik.values.representativeEmail}
              onChange={handleChange}
            />
            <InputErrorMessage
              error={
                formik.touched.representativeEmail &&
                formik.errors.representativeEmail
              }
            />
          </div>
        </div>
        <div className="submitButton mt-4">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
          >
            {!loader ? (
              'Next'
            ) : (
              <div className="text-xs">
                <EditProfileLoader />
              </div>
            )}
          </button>
        </div>

      </section>
    </form>
  );
}
export default RegisterFormFirst;