
import { useEffect, useState, useRef } from 'react';
import { SearchBox, InstantSearch, InfiniteHits, RefinementList, SortBy, Configure, useInstantSearch } from 'react-instantsearch';
import { getAllMashersApi, getUserApi } from '../../apis';
import { EditProfileLoader } from '../common/loader';
import MashCard from '../cards/MashCard';
import './globalSearch.css';
import ConsultationCard from '../ConsultationCard/ConsultationCard';
import Createrequest from '../ConsultationCard/Createrequest';
import ViewRequest from '../ConsultationCard/Viewrequest';
import ConsultationViewRequest from '../ConsultationCard/ConsultationViewRequest';
import BigLoader from './BigLoader';

export default function TypesenseConsultationListComponent({
  searchClient,
  type,
  filterval,
  createRequestBlock,
  handelCreateRequestBlock,
  onload,
  sortOpt }) {
  const [searchWindowClassName, setSearchWindowClassName] = useState('search-box-visible');
  const [editRequestBlock, setEditRequestBlock] = useState(false);
  const [ViewRequestBlock, setViewRequestBlock] = useState(false);
  const [currentrequest, setcurrentrequest] = useState(null);
  const ButtonRef = useRef(null);
  const [profileData, setprofileData] = useState('');

  const MainContainer = () => {
    const { status, refresh, results } = useInstantSearch();
    return status === 'loading' || status === 'stalled' ? (
      <BigLoader />
    ) : <InfiniteHits className='search-row list-style-masher' hitComponent={(hit) =>
      <ConsultationCard {...hit}
        data={hit.hit}
        ViewRequest={() => { handelViewRequestBlock(hit.hit); }}
        editRequest={() => { handelEditRequestBlock(hit.hit); }}
        onload={onload}
        ispage="seperate"
        fillBtnTitle="View "
        unFillBtnTitle="Delete"
        fillBtnfun={() => { handelViewRequestBlock(hit.hit); }} />
    } />;
  };
  const NoResultsBoundary = ({ children, fallback }) => {
    const { results, error } = useInstantSearch();
    if (error) {
      setSearchWindowClassName('search-window-hidden');
      return (
        <div>
          <span className='d-flex align-items-center'><h5 className='m-1'>Note: </h5><p className='m-1'> Use Control/Command + F to search Consultations</p></span>
        </div>);
    }

    if (!results.__isArtificial && results.nbHits === 0) {
      setSearchWindowClassName('search-window-visible');
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }
    setSearchWindowClassName('search-window-visible');
    return children;
  };

  const NoResults = () => {
    const { indexUiState } = useInstantSearch();
    return (
      <div className='no-results-container h-72 flex justify-center items-center'>
        <p className='baseText text-navLink'>
          No Consultations found.
        </p>
      </div>
    );
  };

  function handelEditRequestBlock(data) {
    setEditRequestBlock(!editRequestBlock);
    setcurrentrequest(data);
  }
  function handelViewRequestBlock(data) {

    setViewRequestBlock(!ViewRequestBlock);
    setcurrentrequest(data);
  }
  const fetchData = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        console.log(res.data.Profile);
        setprofileData(res?.data?.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  function getStateWithoutPage(state) {
    const { page, ...rest } = state || {};
    return rest;
  }

  return (
    <div className="col-12">
      <div className='global-search'>
        <InstantSearch
          indexName='odds-hospitalConsultation'
          searchClient={searchClient}
          routing={true}
          filters={'hospitalId:660e988c803eff71d6a91cdf'}>
          <Configure hitsPerPage={15} queryBy={type} query={filterval} filters={`hospitalId:${profileData?.hospitalProfile?._id}  && status:${sortOpt}`} />

          <NoResultsBoundary fallback={<NoResults />}>
            <div className="align-items-start search-container">

              <div className="col-12 search-card-main-window">
                <MainContainer />
              </div>
            </div>
          </NoResultsBoundary>

        </InstantSearch>
      </div>
      {createRequestBlock && (
        <Createrequest closeFunction={handelCreateRequestBlock} editFormValue={null} profileData={profileData} onload={onload} />
      )}
      {editRequestBlock && (
        <Createrequest closeFunction={handelEditRequestBlock} editFormValue={currentrequest} profileData={profileData} onload={onload} />
      )}

      {
        ViewRequestBlock &&
        <ConsultationViewRequest currentdata={currentrequest?._id} profileData={profileData} closeFunction={handelViewRequestBlock} />

      }
    </div>

  );


}