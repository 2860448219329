import React, { useEffect, useState } from 'react';
import { getAllConsultation } from '../../apis';

function ConsultationList() {
  const [data, setData] = useState(null);
  const toGetAllConsultation = async () => {
    const res = await getAllConsultation();
    console.log(res);
    if (res.status === 200) {
      setData(res?.data?.AllHospitalConsultation);
    }
  };
  useEffect(() => {
    toGetAllConsultation();
  }, []);
  return <div>ConsultationList</div>;
}

export default ConsultationList;
