import React, { useEffect } from 'react';
import { useState } from 'react';
import RightBlock from '../../components/signUpLatyout/RightBlock.js';
import { NotifyMeAPi, getProfilebyId, getUserApi } from '../../apis/index.js';
import VerifyScreen from '../../components/VerifyComponent/VerifyScreen.js';
import RegisterForm from '../../components/VerifyComponent/RegistrationScreen.js';
import { EditProfileLoader } from '../../components/loader/loader.js';
import toast from 'react-hot-toast';
function Registration() {
  const [step, setstep] = useState(0);
  function prevStep() {
    if (step > 0) {
      setstep(step - 1);
    }
  }
  function nextStep() {
    setstep(step + 1);
  }
  const [notifyloader,setnotifyloader]=useState(false);
  async function notifyFunction() {
    try {
      setnotifyloader(true)
      const res = await NotifyMeAPi({"profileId":localStorage.getItem('userId')});
      if (res.status === 200) {
        console.log(res.data);
        toast.success(res?.data?.message, { id: '001' });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.error(error);
    }
    finally{
      setnotifyloader(false)
    }
  }
  const[profileData,setprofileData]=useState('');
  const fetchData = async () => {
    try {
      const res = await getProfilebyId(localStorage.getItem('userId'));
      if (res.status === 200) {
        //console.log(res.data.Profile);
        setprofileData(res?.data?.Profile);
        var hospitaldata=res?.data?.Profile?.hospitalProfile;
        if(hospitaldata){
          if(!hospitaldata?.isApproved){
            setstep(2);
          }
          
        }
        
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  switch (step) {
  case 0:
    return (
      <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-center justify-center bg-white">
        <VerifyScreen
          nextStep={nextStep}
          prevStep={prevStep}
          title={'Verify your account'}
          subTitle={
            'Complete the verification process by providing your hospital details to create consultations.'
          }
          btntext={'Take Me to Registration'}
          imgSrc={require('../../Assets/img/registration/verifyregistration.png')}
          btnFunction={nextStep}
          profileData={profileData}
          setprofileData={setprofileData}
        />
        <RightBlock
          imgUrl={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVnaXN0cmF0aW9uJTIwZm9ybXxlbnwwfHwwfHx8MA%3D%3D'
          }
        />
      </section>
    );
    break;
  case 1:
    return (
      <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-start justify-center bg-white">
        <RegisterForm nextScreen={nextStep} prevScreen={prevStep} />
        <RightBlock
          imgUrl={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVnaXN0cmF0aW9uJTIwZm9ybXxlbnwwfHwwfHx8MA%3D%3D'
          }
        />
      </section>
    );

    break;
  case 2:
    return (
      <section className="sign-in w-full h-full min-h-svh lg:max-h-svh overflow-hidden lg:flex items-center justify-center bg-white lg:h-screen">
        <VerifyScreen
          nextStep={nextStep}
          prevStep={prevStep}
          title={'Admin authorization pending'}
          subTitle={
            'The details you have submitted is still pending for verification from admin side.'
          }
          btntext={notifyloader ? <EditProfileLoader /> : 'Notify Me Once Verified'}
          imgSrc={require('../../Assets/img/registration/auth.png')}
          btnFunction={notifyFunction}
          profileData={profileData}
          setprofileData={setprofileData}
        />
        <RightBlock
          imgUrl={
            'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cmVnaXN0cmF0aW9uJTIwZm9ybXxlbnwwfHwwfHx8MA%3D%3D'
          }
        />
      </section>
    );
  default:
    return null;
    break;
  }
}

export default Registration;
