import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../../components/admin-layout';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';

const SectionDivider = () => (
  <div className="mt-4 w-full bg-grey-500 border border-grey-500 border-solid min-h-[1px] max-md:max-w-full" />
);

const ToggleOption = ({ title, isLast }) => (
  <>
    <div className="flex justify-between items-center w-full text-base font-light tracking-tight text-slate-500">
      <div>{title}</div>
      <div className="flex flex-col justify-center items-start px-4 py-0.5 bg-grey-500 rounded-[30px]">
        <div className="shrink-0 bg-white rounded-full h-[18px]" />
      </div>
    </div>
    {!isLast && <SectionDivider />}
  </>
);

const OptionRow = ({ iconSrc, title, rightIconSrc }) => (
  <div className="flex justify-between items-center w-full text-base font-light tracking-tight text-slate-500">
    <div className="flex gap-4">
      <img loading="lazy" src={iconSrc} className="shrink-0 w-8 aspect-square" />
      <div className="my-auto">{title}</div>
    </div>
    <img loading="lazy" src={rightIconSrc} className="shrink-0 my-auto aspect-[0.54] w-[7px]" />
  </div>
);

const options = [
  { title: "Change Password", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/5560992f426285f72d8c27e4c0b13a16b04a793dec5f91a3830ed5405701d729?", rightIconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e29d0a8cccda2412fedcb5cfbf3662663dd3993286b86ca3b124c3b3b4422bd5?", },
  { title: "Notifications", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e87e2bff54b77617cc4a2cb281caff9b0e3582d81fd9a44161b3e47f9ddc695c?", rightIconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e29d0a8cccda2412fedcb5cfbf3662663dd3993286b86ca3b124c3b3b4422bd5?" },
  { title: "Privacy and Safety", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/fa6a13056d088dc18aa8f6602d7185bf199b032fc1ce68b0b32ef56abe29a6d8?", rightIconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e29d0a8cccda2412fedcb5cfbf3662663dd3993286b86ca3b124c3b3b4422bd5?" },
  { title: "About us", iconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/af7d83ca7152737af7b81674edb4f73207f90867da5f857372f131c5ec7ab6fd?", rightIconSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e29d0a8cccda2412fedcb5cfbf3662663dd3993286b86ca3b124c3b3b4422bd5?" },
];

function UserSettings() {
  return (
    <div className="flex flex-col p-4 max-w-[511px]">
      <div className="flex items-center gap-2  min-h-10 mb-2 xl:hidden sticky top-[-1px] py-3 bg-white z-10">
          <HiOutlineArrowSmallLeft
              className="text-base text-coalBlack p-1.5 w-9 h-9 rounded-full active:bg-blue-50 xl:hidden"
              onClick={() => {
              window.history.back();
              }}
          />
          <p className="text-2xl font-medium text-coalBlack">Account settings</p>
      </div>

      {options.map((option, index) => (
        <React.Fragment key={index}>
          <OptionRow {...option} />
          <SectionDivider />
        </React.Fragment>
      ))}
      {/* <div className="mt-7 w-full text-base font-medium tracking-tight leading-7 text-slate-500 max-md:max-w-full">
        More options
      </div>
      <ToggleOption title="Text messages" />
      <ToggleOption title="Phone calls" />
      <ToggleOption title="Linked accounts" isLast /> */}

    <div className="mt-4 w-full bg-grey-500 min-h-[1px] max-md:max-w-full" />
      <div className="mt-7 w-full text-base font-medium tracking-tight leading-7 text-slate-500 max-md:max-w-full">
        More options
      </div>
      <div className="flex gap-5 px-5 mt-5 w-full max-md:flex-wrap max-md:max-w-full">
        <div className="flex-auto text-base font-light tracking-tight text-slate-500">
          Text messages
        </div>
        <div className="flex flex-col justify-center items-start px-4 py-0.5 bg-grey-500 rounded-[30px]">
          <div className="shrink-0 bg-white rounded-full h-[18px]" />
        </div>
      </div>
      <div className="mt-3.5 w-full border border-solid bg-grey-500 bg-opacity-10 border-grey-500 border-opacity-10 min-h-[1px] max-md:max-w-full" />
      <div className="flex gap-5 justify-between px-5 mt-3.5 w-full max-md:flex-wrap max-md:max-w-full">
        <div className="text-base font-light tracking-tight text-slate-500">
          Phone calls
        </div>
        <div className="flex flex-col justify-center items-start px-4 py-0.5 bg-grey-500 rounded-[30px]">
          <div className="shrink-0 bg-white rounded-full h-[18px]" />
        </div>
      </div>
      <div className="mt-4 w-full border border-solid bg-grey-500 bg-opacity-10 border-grey-500 border-opacity-10 min-h-[1px] max-md:max-w-full" />
      <div className="flex gap-5 justify-between px-5 mt-5 w-full font-light tracking-tight whitespace-nowrap text-slate-500 max-md:flex-wrap max-md:max-w-full">
        <div className="text-base">Languages</div>
        <div className="flex gap-3.5 self-start text-xs">
          <div>English</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/93f128e401d63b9d168a93218c2dd7da3be5d6bd47ec2f16f109d4df384d27ff?"
            className="shrink-0 self-start w-2 aspect-[0.61]"
          />
        </div>
      </div>
      <div className="mt-4 w-full border border-solid bg-grey-500 bg-opacity-10 border-grey-500 border-opacity-10 min-h-[1px] max-md:max-w-full" />
      <div className="flex gap-5 px-5 mt-4 w-full font-light tracking-tight whitespace-nowrap text-slate-500 max-md:flex-wrap max-md:max-w-full">
        <div className="text-base">Currency</div>
        <div className="flex flex-1 gap-5 justify-between self-start text-xs">
          <div>INR</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d57c117394913999f776240dc421d0d2280980290f32865bcf8400b0d560a07?"
            className="shrink-0 w-2 aspect-[0.61]"
          />
        </div>
      </div>
      <div className="mt-4 w-full border border-solid bg-grey-500 bg-opacity-10 border-grey-500 border-opacity-10 min-h-[1px] max-md:max-w-full" />
      <div className="flex gap-5 items-start px-5 pt-px pb-4 mt-3.5 w-full font-light tracking-tight text-slate-500 max-md:flex-wrap max-md:max-w-full">
        <div className="flex-auto text-base">Linked accounts</div>
        <div className="flex gap-2 text-xs whitespace-nowrap">
          <div>Google</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/bef0c8cee562a87cba3e9a77424cb3c1cada9f2763841658585824c6167359a5?"
            className="shrink-0 self-start w-2 aspect-[0.61]"
          />
        </div>
      </div>
    </div>
  );
}

export default UserSettings;
