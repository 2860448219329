import React, { useState, useContext, useEffect, useRef } from 'react';
import AdminLayout from '../../components/admin-layout';
import MessageCard from '../../components/MessageNotification/MessageCard';
import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
import { AppContext } from '../../appContext';
import { getNotificationAPI, getOddsWeavyTokenApi } from '../../apis';
import ConsultationViewRequest from '../../components/ConsultationCard/ConsultationViewRequest';
import { EditProfileLoader } from '../../components/common/loader';
import {
  useWeavy,
  WyChat,
  WyNotificationToasts,
  Weavy,
  WyNotifications,
} from '@weavy/uikit-react';
import WeavyNotification from '../../components/oddsChat/component/WeavyNotification';
import { BsFillChatLeftDotsFill } from 'react-icons/bs';

function NotificationOdds() {
  const { loggedInUser, chatCount } = useContext(AppContext);
  const [notification, setNotification] = useState(null);
  const [profileData, setProfileData] = useState('');
  const [currentRequest, setCurrentRequest] = useState(null);
  const [viewRequestBlock, setViewRequestBlock] = useState(false);
  const [loader, setLoader] = useState(false);
  const weavyInstanceRef = useRef(null); // Use useRef to keep a mutable reference

  const getNotification = async (userId) => {
    try {
      const res = await getNotificationAPI(userId);
      if (res.status === 200) {
        setNotification(res.data.hospitalNotification);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loggedInUser && loggedInUser != null) {
      setProfileData(loggedInUser?.data?.Profile);
      getNotification(loggedInUser?.data?.hospitalProfile?._id);
    }
  }, [loggedInUser]);

  const handleViewRequestBlock = (data) => {
    setViewRequestBlock(!viewRequestBlock);
    setCurrentRequest(data);
  };

  const mapFunctionWithNullCheck = (dataArray, component) => {
    if (!dataArray) return null;
    return dataArray.map((data, i) => (
      <MessageCard
        data={data}
        notificationfun={() => {
          getNotification(loggedInUser?.data?.hospitalProfile?._id);
        }}
        viewFunction={handleViewRequestBlock}
        setcurrentrequest={setCurrentRequest}
        profileData={profileData}
        key={i}
      />
    ));
  };

  const allMsgBlock = mapFunctionWithNullCheck(
    notification?.allNotification,
    MessageCard
  );
  const readMsgBlock = mapFunctionWithNullCheck(
    notification?.readNotification,
    MessageCard
  );
  const unreadMsgBlock = mapFunctionWithNullCheck(
    notification?.unReadNotification,
    MessageCard
  );

  const [tab, setTab] = useState(1);
  const handleTabs = (prop) => {
    setTab(prop);
  };

  return (
    <AdminLayout>
      <div className="p-4 md:px-8 2xl:container 2xl:mx-auto bg-white min-h-screen pb-20 xl:pb-0">
        <div className="flex items-center gap-2 min-h-10 mb-2 xl:hidden sticky top-[-1px] py-3 bg-white z-10">
          <HiOutlineArrowSmallLeft
            className="text-base text-coalBlack p-1.5 w-9 h-9 rounded-full active:bg-blue-50 xl:hidden"
            onClick={() => {
              window.history.back();
            }}
          />
          <p className="headingText text-coalBlack">Notification</p>
        </div>
        <div className="flex items-center gap-6 mt-5 p-4 border-b border-gray-200">
          <p
            className={`!font-medium baseText cursor-pointer capitalize border-b-[3px] hover:text-accent ${
              tab === 1
                ? 'border-accent text-accent'
                : 'text-navLink border-transparent'
            }`}
            onClick={() => {
              handleTabs(1);
            }}
          >
            All
          </p>
          <p
            className={`!font-medium baseText cursor-pointer capitalize border-b-[3px] hover:text-accent ${
              tab === 2
                ? 'border-accent text-accent'
                : 'text-navLink border-transparent'
            }`}
            onClick={() => {
              handleTabs(2);
            }}
          >
            Read
          </p>
          <p
            className={`!font-medium baseText cursor-pointer capitalize border-b-[3px] hover:text-accent ${
              tab === 3
                ? 'border-accent text-accent'
                : 'text-navLink border-transparent'
            }`}
            onClick={() => {
              handleTabs(3);
            }}
          >
            Unread
          </p>
          <div
            className="relative inline-flex items-center gap-x-1  rounded-full p-1  cursor-pointer"
            onClick={() => {
              handleTabs(4);
            }}
          >
            <p
              className={`!font-medium baseText cursor-pointer capitalize border-b-[3px] hover:text-accent ${
                tab === 4
                  ? 'border-accent text-accent'
                  : 'text-navLink border-transparent'
              }`}
              onClick={() => {
                handleTabs(4);
              }}
            >
              Message{' '}
            </p>

            {chatCount > 0 ? (
              <span className="p-1 inline-flex justify-center items-center min-w-[18px] h-[18px] rounded-full bg-blue-400 text-white !font-semibold smallText">
                {chatCount}
              </span>
            ) : null}
          </div>
        </div>
        {notification ? (
          <>
            {tab === 1 && (
              <>
                {notification?.allNotification?.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-content-center gap-4 md:gap-6 xl:gap-8 lg:p-5">
                    {allMsgBlock}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center w-full p-10">
                    There are no notifications for me to display at the moment.
                  </p>
                )}
              </>
            )}
            {tab === 2 && (
              <>
                {notification?.readNotification?.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-content-center gap-4 md:gap-6 xl:gap-8 lg:p-5">
                    {readMsgBlock}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center w-full p-10">
                    There are no notifications for me to display at the moment.
                  </p>
                )}
              </>
            )}
            {tab === 3 && (
              <>
                {notification?.unReadNotification?.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-content-center gap-4 md:gap-6 xl:gap-8 lg:p-5">
                    {unreadMsgBlock}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center w-full p-10">
                    There are no notifications for me to display at the moment.
                  </p>
                )}
              </>
            )}
            {tab === 4 && (
              <div>
                <p>
                  <WeavyNotification />
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="w-full flex item-center justify-center mt-5">
            <EditProfileLoader />
          </div>
        )}
      </div>

      {viewRequestBlock && (
        <ConsultationViewRequest
          currentdata={currentRequest}
          profileData={profileData}
          closeFunction={handleViewRequestBlock}
        />
      )}
    </AdminLayout>
  );
}

export default NotificationOdds;
