// import React, { useState } from 'react';
// import { Formik, Field, Form } from 'formik';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import AdminLayout from '../../components/admin-layout';
// import { HiOutlineArrowSmallLeft } from 'react-icons/hi2';
// import { FiChevronLeft } from 'react-icons/fi';
// import { AiOutlineUser } from 'react-icons/ai';
// import { IoVideocam } from 'react-icons/io5';
// import { FaTags } from 'react-icons/fa';
// import { useFormik } from 'formik';
// import { InputErrorMessage } from '../../components/errorMessages';
// import { ConsultationRequest } from '../../components/Validation';
// import { HiOutlinePlus } from 'react-icons/hi2';
// import { BiSolidReport } from 'react-icons/bi';
// import { FaFilePrescription } from 'react-icons/fa';
// import { EditProfileLoader } from '../../components/common/loader';
// import { GrNotes } from 'react-icons/gr';
// import { FaLocationDot } from 'react-icons/fa6';
// import { IoMdCall } from 'react-icons/io';
// import { TagsInput } from 'react-tag-input-component';
// import {useDropzone} from 'react-dropzone';


// function ProfilePopup({ closeFunction, editFormValue }) {
//   const [selected, setSelected] = useState(['fever']);
//   const [loader, setLoader] = useState(false);

//   const formik = useFormik({
//     initialValues: {
//       name: 'Sanjay Kumar',
//       contactNumber: '+91 764543356',
//       dob: 'DD-MM-YYYY',
//       location: '',
//     },
//   });
//   const { handleSubmit, handleChange, values, touched, errors } = formik;

//   let createBtnText ='Create Request';
//   if (editFormValue != undefined) {
//     formik.values = editFormValue;
//     createBtnText='Update Request';
//   }
//   return (
//     <section className="absolute top-0 left-0 w-full h-screen z-50">
//       <div
//         className="absolute top-0 left-0 w-full h-full bg-inherit"
//         onClick={closeFunction}
//       ></div>
//       <div className="relative z-20 m-auto overflow-y-auto scrollbar lg:max-w-lg lg:py-4 lg:px-8">

//         <main className="bg-white p-4 pt-0 pb-16  lg:rounded-2xl lg:py-5">
//           {/* Profile header  */}
//             <div className=" bg-white flex items-center gap-2 sticky top-0 py-2 z-30 lg:hidden">
//                 <FiChevronLeft
//                 className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
//                 onClick={closeFunction}
//                 />
//                 <p className="text-lg font-medium text-coalBlack capitalize">
//                   Profile detail
//                 </p>
//             </div>

//             <Formik
//                 initialValues={{
//                     name: 'Sanjay Kumar',
//                     contactNumber: '+91 764543356',
//                     dob: 'DD-MM-YYYY',
//                     location: '',
//                 }}
//                 onSubmit={values => {
//                     // submit form values to servers
//                 }}
//             >
//                 {({ isSubmitting }) => (

//                     <Form action="#" className="">
//                         <div className="">
//                             <div className="flex flex-col font-medium">
//                                 <div className="flex gap-4 px-4 py-4 text-2xl text-black">
//                                     <div className="flex-auto">Personal Details</div>
//                                 </div>
//                                 <div className="flex flex-col items-start mt-6 w-full text-base text-slate-500">
                                    
//                                     <img 
//                                         loading="lazy" 
//                                         src="https://th.bing.com/th/id/OIP.j8yd8dJ5215WbgQ0NsLzuAHaNK?rs=1&pid=ImgDetMain"
//                                         className="w-20 h-20 rounded-full bg-blue-400 object-cover"
//                                     />

//                                     <div className="mt-3.5 tracking-tight">Edit your profile image</div>
//                                     <div className="mt-11 text-lg text-zinc-800">Personal information</div>
                                    
                                    
//                                     <div className="mt-5 text-sm text-black">Name</div>
//                                     <Field name="name" className="flex w-full gap-5 px-5 py-5 mt-4 bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
//                                     <div className="mt-4 text-sm text-black">Contact Number</div>
//                                     <Field name="contactNumber" className="flex w-full gap-5 px-5 py-5 mt-4 bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
//                                     <div className="mt-3.5 text-sm text-black">Date of birth</div>
//                                     <Field name="dob" className="flex w-full gap-5 px-5 py-5 mt-4 whitespace-nowrap bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
//                                     <div className="mt-3.5 text-sm text-black">Location</div>
//                                     <Field name="location" className="justify-center items-start w-full px-6 py-5 mt-4 bg-white rounded-xl border border-solid border-slate-500 border-opacity-20" />
//                                     <button type="submit" disabled={isSubmitting} className="justify-center w-full items-center px-16 py-4 mt-6 text-2xl text-white whitespace-nowrap bg-blue-700 rounded-md">
//                                         Update
//                                     </button>
//                                 </div>
//                             </div>
                            
//                         </div>
//                     </Form>
//                 )}
//             </Formik>

//         </main>


//       </div>
//     </section>
//   );
// }

// export default ProfilePopup;
