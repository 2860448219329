import React, { useState, useCallback } from 'react';
import { FaAddressCard } from 'react-icons/fa';
import { FiFileText } from 'react-icons/fi';
import { InputErrorMessage } from '../../components/errorMessages/index.js';
import { EditProfileLoader } from '../loader/loader.js';
import { LuUpload } from 'react-icons/lu';
import { fileUploadApi } from '../../apis/index.js';
import { FileUploadComponent,FileprofileComponent,profileFileUploadComponent } from '../upload/FileUploadComponent.js';
import { XCircleFill } from 'react-bootstrap-icons';
import { useDropzone } from 'react-dropzone';

function RegisterFormSecond({ formik, loader, setLoader }) {
  const handleChange = (e) => {
    e.preventDefault();
    formik.handleChange(e);
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [filename, setFileName] = useState('');
  const [filesize, setFileSize] = useState('');
  const [fileiserror, setFileIsError] = useState(false);
  const [isfile, setIsFile] = useState(false);
  const [profileuploadPercentage, setprofileUploadPercentage] = useState(0);
  const [profilename, setprofileName] = useState('');
  const [profilesize, setprofilesize] = useState('');
  const [profileiserror, setprofileIsError] = useState(false);
  const [isprofile, setisprofile] = useState(false);
  const onDropProfile = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await profilefileUpload(file); // Ensure this function is defined to handle the upload
      // Here you might want to update Formik's state or component state as necessary
    }
  }, []); // Removed formik from dependency array unless directly used

  const {
    getRootProps: getRootPropsProfile,
    getInputProps: getInputPropsProfile,
    isDragActive: isDragActiveProfile,
  } = useDropzone({
    onDrop: onDropProfile,
    // add `accept` prop if you want to restrict file types
  });
  const [coveruploadPercentage, setcoverUploadPercentage] = useState(0);
  const [covername, setcoverName] = useState('');
  const [coversize, setcoversize] = useState('');
  const [coveriserror, setcoverIsError] = useState(false);
  const [iscover, setiscover] = useState(false);
  const onDropCover = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      await coverfileUpload(file); // Ensure this function is defined to handle the upload
      // Update component state as necessary
    }
  }, []); // Removed formik from dependency array unless directly used

  const {
    getRootProps: getRootPropsCover,
    getInputProps: getInputPropsCover,
    isDragActive: isDragActiveCover,
  } = useDropzone({
    onDrop: onDropCover,
    // add `accept` prop if you want to restrict file types
  });
  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }

  const onUploadProgress = (progressEvent, file) => {
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setUploadPercentage(progress);
    setFileName(file.name);
    setFileSize(formatFileSize(file.size));
  };

  const fileUpload = async (file) => {
    try {
      setIsFile(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', null);

      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        setFileName(file.name);
        setFileSize(formatFileSize(file.size));
        setIsFile(false);
        formik.setFieldValue('licencename', file.name);
        formik.setFieldValue('licencesize', file.size);
        // Assuming uploadResponse.data.filePath contains the file path
        formik.setValues({
          ...formik.values,
          hospitalLicense: uploadResponse.data.filePath,
        });
      } else {
        setFileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setFileIsError(true);
    } finally {
      setIsFile(false);
    }
  };
  const profileonUploadProgress = (progressEvent, file) => {
    console.log(progressEvent);
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setprofileUploadPercentage(progress);
    setprofileName(file.name);
    setprofilesize(formatFileSize(file.size));
  };

  const profilefileUpload = async (file) => {
    try {
      setisprofile(true);
      const formData = new FormData();
      formData.append('file', file);

      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        profileonUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // Here we update only the profile field
        formik.setFieldValue('profile', uploadResponse.data.filePath);

        setisprofile(false);
      } else {
        setprofileIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setprofileIsError(true);
    } finally {
      setisprofile(false);
    }
  };

  const coveronUploadProgress = (progressEvent, file) => {
    console.log(progressEvent);
    const progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );
    setcoverUploadPercentage(progress);
    setcoverName(file.name);
    setcoversize(formatFileSize(file.size));
  };

  const coverfileUpload = async (file) => {
    try {
      setiscover(true);
      const formData = new FormData();
      formData.append('file', file);

      // Make the file upload request
      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        coveronUploadProgress(progressEvent, file)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        // Here we update only the cover field
        formik.setFieldValue('cover', uploadResponse.data.filePath);
        setiscover(false);
      } else {
        setcoverIsError(true);
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setcoverIsError(true);
    } finally {
      setiscover(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await fileUpload(file);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="pb-6">
      <section>
        <section className="shadow-md rounded-xl bg-white space-y-4 mt-6 p-4 md:shadow-none">
          <h3 className="text-lg capitalize font-medium">Additional Details</h3>
          <div className="RegistrationNumber">
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Registration Number
            </p>
            <div className="relative">
              <input
                type="text"
                name="RegistrationNumber"
                className={
                  Boolean(formik.touched.RegistrationNumber) &&
                  Boolean(formik.errors.RegistrationNumber)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="RegistrationNumber"
                placeholder="474RHFBR57HY"
                value={formik.values.RegistrationNumber}
                onChange={handleChange}
              />
              <InputErrorMessage
                error={
                  formik.touched.RegistrationNumber &&
                  formik.errors.RegistrationNumber
                }
              />
            </div>
          </div>
          <div className="capacity">
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Hospital Capacity (No. of Beds)
            </p>
            <div className="relative">
              <input
                type="text"
                name="capacity"
                className={
                  Boolean(formik.touched.capacity) &&
                  Boolean(formik.errors.capacity)
                    ? 'form-control border-danger transparent-input-border'
                    : 'form-control transparent-input-border'
                }
                id="capacity"
                placeholder="Total Beds Available"
                value={formik.values.capacity}
                onChange={handleChange}
              />
              <InputErrorMessage
                error={formik.touched.capacity && formik.errors.capacity}
              />
            </div>
          </div>
        </section>

        <section className="shadow-md rounded-xl bg-white space-y-4 mt-4 p-4 md:shadow-none">
          <h3 className="text-lg capitalize font-medium">Submit Documents</h3>
          <div className="bg-[#E8EAF7] p-3 rounded-lg flex gap-2 items-center select-none">
            <div>
              <FiFileText className="text-2xl text-accent" />
            </div>
            <p className="text-sm font-normal text-[#696F8C]">
              We are required by law to verify hospital identity by collecting
              certain documents.
            </p>
          </div>
          <div className="uploadDocument">
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Upload Document
            </p>
            {!isfile && (
              <>
                <div className="relative cursor-pointer">
                  <div className="flex gap-2 items-center justify-between shadow-md rounded-2xl p-3 bg-white">
                    <div className="flex gap-2 items-center p-2 max-w-[75%]">
                      <div>
                        <FaAddressCard className="text-2xl text-accent" />
                      </div>
                      <p className="text-sm font-medium text-coalBlack  capitalize ps-2 border-l border-gray-200 truncate whitespace-nowrap">
                        {formik.values.hospitalLicense === undefined
                          ? 'Hospital License'
                          : formik.values.hospitalLicense}
                      </p>
                    </div>
                    <div className="relative">
                      <LuUpload className="text-2xl text-coalBlack" />
                    </div>
                    <input
                      type="file"
                      name="hospitalLicense"
                      id="hospitalLicense"
                      className="fileInput"
                      onChange={handleFileChange}
                      accept=".pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                  </div>
                  <div className="mt-2">
                    <InputErrorMessage
                      error={
                        formik.touched.hospitalLicense &&
                        formik.errors.hospitalLicense
                      }
                    />
                  </div>
                </div>
              </>
            )}
            {isfile && (
              <FileUploadComponent
                name={filename}
                filesize={filesize}
                percentage={uploadPercentage}
                isfileerror={fileiserror}
              />
            )}
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Upload Profile Photo
            </p>

            <>
              <div>
                <div>
                  <div className="flex gap-4 input-group">
                    <div className="w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0">
                      {isprofile ? (
                        <FileprofileComponent
                          name={profilename}
                          filesize={profilesize}
                          percentage={profileuploadPercentage}
                        />
                      ) : (
                        <>
                          {formik.values.profile && (
                            <div className="flex flex-col items-center  w-[100%]">
                              {formik.values.profile && (
                                <div className="flex flex-row justify-between  w-[100%] mb-4">
                                  <div className="truncate w-[80%]">
                                    {' '}
                                    {profilename}
                                  </div>
                                  <XCircleFill
                                    className="cursor-pointer"
                                    onClick={() => {
                                      formik.setFieldValue('profile', '');
                                    }}
                                  />
                                </div>
                              )}
                              <img src={formik.values.profile} />
                            </div>
                          )}
                          {!formik.values.profile && (
                            <div
                              {...getRootPropsProfile()}
                              className="w-[100%]"
                            >
                              <input {...getInputPropsProfile()} />
                              {isDragActiveProfile ? (
                                <div className="flex flex-col items-center text-center">
                                  <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                    <i className="bi bi-cloud-arrow-up text-2xl text-[#475467]" />
                                  </div>
                                  <p className="font-semibold text-[#00A46F] mt-6">
                                    Drop here
                                  </p>
                                  <p>PNG, JPG or GIF (min. 200px)</p>
                                </div>
                              ) : (
                                <div className="flex flex-col items-center text-center">
                                  <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                    <i className="bi bi-cloud-arrow-up text-2xl text-[#1648CE]" />
                                  </div>
                                  <p className="font-semibold text-[#1648CE] mt-6">
                                    Click to upload
                                    <span className="text-[#667085] font-normal hidden md:block">
                                      order drag and drop
                                    </span>
                                  </p>
                                  <p>PNG, JPG</p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <InputErrorMessage
                error={formik.touched.profile && formik.errors.profile}
              />
            </>
            <p className="text-sm font-medium text-coalBlack mb-2 capitalize">
              Upload Cover Photo
            </p>

            <>
              <div>
                <div>
                  <div className="flex gap-4 input-group">
                    <div className="w-full rounded-lg border border-[#D0D5DD] p-8 flex items-center justify-center mt-0">
                      {iscover ? (
                        <FileprofileComponent
                          name={covername}
                          filesize={coversize}
                          percentage={coveruploadPercentage}
                        />
                      ) : (
                        <>
                          {formik.values.cover && (
                            <div className="flex flex-col items-center  w-[100%]">
                              {formik.values.cover && (
                                <div className="flex flex-row justify-between  w-[100%] mb-4">
                                  <div className="truncate w-[80%]">
                                    {' '}
                                    {covername}
                                  </div>
                                  <XCircleFill
                                    className="cursor-pointer"
                                    onClick={() => {
                                      formik.setFieldValue('cover', '');
                                    }}
                                  />
                                </div>
                              )}
                              <img src={formik.values.cover} />
                            </div>
                          )}
                          {!formik.values.cover && (
                            <div {...getRootPropsCover()} className="w-[100%]">
                              <input {...getInputPropsCover()} />
                              {isDragActiveCover ? (
                                <div className="flex flex-col items-center text-center">
                                  <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                    <i className="bi bi-cloud-arrow-up text-2xl text-[#1648CE]" />
                                  </div>
                                  <p className="font-semibold text-[#00A46F] mt-6">
                                    Drop here
                                  </p>
                                  <p>PNG, JPG or GIF (min. 200px)</p>
                                </div>
                              ) : (
                                <div className="flex flex-col items-center text-center">
                                  <div className="flex justify-center items-center w-12 h-12 rounded-full bg-[#F2F4F7]">
                                    <i className="bi bi-cloud-arrow-up text-2xl text-[#1648CE]" />
                                  </div>
                                  <p className="font-semibold text-[#1648CE] mt-6">
                                    Click to upload
                                    <span className="text-[#1648CE] font-normal hidden md:block">
                                      order drag and drop
                                    </span>
                                  </p>
                                  <p>PNG, JPG</p>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <InputErrorMessage
                error={formik.touched.cover && formik.errors.cover}
              />
            </>
          </div>
        </section>
        <div className="submitButton mt-4">
          <button
            className="btn btn_primary_black text-white w-full rounded-xl"
            type="submit"
            disabled={loader}
          >
            {!loader ? 'Submit' : <EditProfileLoader />}
          </button>
        </div>
      </section>
    </form>
  );
}

export default RegisterFormSecond;
