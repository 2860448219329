import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Link, Route, Routes } from 'react-router-dom';
import NotFound from '../Pages/404';
import EditProfile from '../Pages/Account/EditProfile';
import Otp from '../Pages/Account/Otp';
import SignIn from '../Pages/Account/signIn';
import BriefView from '../Pages/Briefs/BriefView.js';
import BriefPDFView from '../Pages/Briefs/BriefPDFView.js';
import CreateBrief from '../Pages/Briefs/CreateBrief';
import EditBrief from '../Pages/Briefs/EditBrief/EditBriefIndex.js';
import Briefs from '../Pages/Briefs/BriefListView.js';
import Brands from '../Pages/Brands';
import { ClientProfile } from '../Pages/Brands/BrandProfile';
import CreateClient from '../Pages/Brands/CreateBrand';
import EditClient from '../Pages/Brands/EditBrand';
import EditMasher from '../Pages/Mashers/EditMasher';
import Mashers from '../Pages/Mashers/index';
import MashersProfile from '../Pages/Mashers/MashersProfile.js';
import Convert from '../Pages/Project/Convert';
import Edit from '../Pages/Project/Edit';
import ProjectView from '../Pages/Project/ProjectView';
import BriefToProject from '../Pages/Briefs/BriefToProject.js';
import PrivateRoute from './privateRoute';
import PublicRoute from './PublicRoute';
import GoogleSignIn from '../Pages/Account/googleSignIn';
import CompaniesListView from '../Pages/Companies/CompaniesListView.js';
import ProjectListView from '../Pages/Project/ProjectListView.js';
import ProjectViewV2 from '../Pages/Project/ProjectViewV2.js';
import { getUserApi } from '../apis/index.js';
import Registration from '../Pages/RegistrationPage/Registration.js';
import DashboardOdds from '../Pages/DashboardOdds/Index.js';
import NotificationOdds from '../Pages/NotificationOdds/NotificationOdds.js';
import UserProfile from '../Pages/UserProfile/ProfilePage.js';
import UserSettings from '../Pages/UserProfile/UserSettings.js';
import Index from '../Pages/Consultation/Index.js';
import ProfileLayout from '../Pages/ProfileLayout/ProfileLayout.js';
import PersonalDetails from '../Pages/ProfileLayout/PersonalDetails.js';
import AppointmentHistory from '../Pages/ProfileLayout/AppoinmentHistory.js';
import ProfileSettings from '../Pages/ProfileLayout/ProfileSettings.js';
import DeleteAccount from '../Pages/ProfileLayout/DeleteAccount.js';
import Logout from '../Pages/ProfileLayout/Logout.js';
import ChangePassword from '../Pages/ProfileLayout/ChangePassword.js';
import WalletIndex from '../Pages/Wallet/WalletIndex.js';

export default function MainRouter() {
  const [loggedInUser, setLoggedInUser] = useState();

  const getLoggedInUser = async () => {
    try {
      const res = await getUserApi();
      if (res.status === 200) {
        setLoggedInUser(res.data.Profile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <>
      <Routes>
        <Route />
        {/* Mashers routessssssssssssssssssssssss  */}
        <Route
          path="/influencer"
          element={
            <PrivateRoute>
              <Mashers />
            </PrivateRoute>
          }
        />
        {/* <Route
          path='/mashers/mashers-profile/:masherId'
          element={
            <PrivateRoute>
              <MashersProfile />
            </PrivateRoute>
          }
        /> */}

        {/* Project routessssssssssssssssssssssss  */}
        {/* <Route
          path='/projects/'
          element={
            <PrivateRoute>
              <ProjectListView />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/Campaigns/"
          element={
            <PrivateRoute>
              <ProjectListView />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/project-view/:projectId"
          element={
            <PrivateRoute>
              <ProjectViewV2 />
            </PrivateRoute>
          }
        />
        <Route
          path="/campaigns/campaigns-view/:projectId"
          element={
            <PrivateRoute>
              <ProjectViewV2 />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/edit-project/:projectId"
          element={
            <PrivateRoute>
              <Edit />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/convert-to-project"
          element={
            <PrivateRoute>
              <Convert />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/convert-to-project/:briefId"
          element={
            <PrivateRoute>
              <Convert />
            </PrivateRoute>
          }
        />
        {/* Client routessssssssssssssssssssssss  */}
        <Route
          path="/brands"
          element={
            <PrivateRoute>
              <CompaniesListView />
            </PrivateRoute>
          }
        />
        <Route
          path="/brands/create-brand"
          element={
            <PrivateRoute>
              <CreateClient />
            </PrivateRoute>
          }
        />
        <Route
          path="/brands/edit-brand/:clientId"
          element={
            <PrivateRoute>
              <EditClient />
            </PrivateRoute>
          }
        />
        <Route
          path="/brands/brand-profile/:clientId"
          element={
            <PrivateRoute>
              <ClientProfile />
            </PrivateRoute>
          }
        />

        {/* Brief routessssssssssssssssssssssss  */}
        <Route
          path="/briefs"
          element={
            <PrivateRoute>
              <Briefs currentUser={loggedInUser} />
            </PrivateRoute>
          }
        />

        <Route
          path="/briefs/brief-view/:_id"
          element={
            <PrivateRoute>
              <BriefView />
            </PrivateRoute>
          }
        />

        <Route
          path="/briefs/brief-pdf-view/:_id"
          element={
            <PrivateRoute>
              <BriefPDFView />
            </PrivateRoute>
          }
        />

        <Route
          path="/briefs/create-brief/"
          element={
            <PrivateRoute>
              <CreateBrief />
            </PrivateRoute>
          }
        />
        <Route
          path="/briefs/create-brief/:id"
          element={
            <PrivateRoute>
              <CreateBrief />
            </PrivateRoute>
          }
        />
        <Route
          path="/briefs/edit-brief/:_id"
          element={
            <PrivateRoute>
              <EditBrief />
            </PrivateRoute>
          }
        />
        <Route
          path="/briefs/convert-brief/:_id"
          element={
            <PrivateRoute>
              <BriefToProject />
            </PrivateRoute>
          }
        />

        {/* Other routessssssssssssssssssssssss  */}
        {/* <Route
          exact
          path="/"
          element={
            <PrivateRoute>
              <Dashboard currentUser={loggedInUser} />
            </PrivateRoute>
          }
        /> */}
        <Route
          path="/edit-profile"
          element={
            <PrivateRoute>
              <EditProfile />
            </PrivateRoute>
          }
        />
      

        <Route
          path="/setting/:masherId"
          element={
            <PrivateRoute>
              <EditMasher />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/sign-in"
          element={
            <PublicRoute>
              <GoogleSignIn />
            </PublicRoute>
          }
        /> */}
        <Route
          path="/sign-up"
          element={
            <PublicRoute>
              <GoogleSignIn
                formType={'SIGNUP'}
                formGreetings={'Join Hospital Portal !'}
                formSubGreetings={'Enter your email and password to sign up'}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/sign-in"
          element={
            <PublicRoute>
              <GoogleSignIn
                formGreetings={'Welcome Back !'}
                formSubGreetings={'Enter your email and password to sign in'}
              />
            </PublicRoute>
          }
        />
        <Route
          path="/otp"
          element={
            <PublicRoute>
              <Otp />
            </PublicRoute>
          }
        />
        <Route
          path="Registration"
          element={
            <PublicRoute>
              <Registration />
            </PublicRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardOdds currentUser={loggedInUser} />
            </PrivateRoute>
          }
        />
      
        <Route
          path="notification"
          element={
            <PrivateRoute>
              <NotificationOdds />
            </PrivateRoute>
          }
        />
        <Route
          path="consultation"
          element={
            <PrivateRoute>
              <Index />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <WalletIndex />
            </PrivateRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <ProfileLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="/profile/appoinment"
            element={
              <PrivateRoute>
                <AppointmentHistory />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/details"
            element={
              <PrivateRoute>
                <PersonalDetails />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/profile/settings"
            element={
              <PrivateRoute>
                <ProfileSettings></ProfileSettings>
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/profile/changepassword"
            element={
              <PrivateRoute>
                <ChangePassword/>
              </PrivateRoute>
            }
          />
         
          <Route
            path="/profile/deleteaccount"
            element={
              <PrivateRoute>
                <DeleteAccount />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/logout"
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />
          
        </Route>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerStyle={{ zIndex: '99999' }}
      />
    </>
  );
}


