import React, { useContext, useEffect, useState } from 'react';
import { BsCurrencyRupee } from 'react-icons/bs';
import { checkMonthlyDue } from '../../apis';
import moment from 'moment';
import { AppContext } from '../../appContext';

function WithdrawRequest() {
  const { loggedInUser } = useContext(AppContext);
  const [dueDetails, setDueDetails] = useState(null);
  const [loader, setLoader] = useState(true);
  const toCheckMonthlyDue = async (id) => {
    try {
      const res = await checkMonthlyDue(id);
      if (res.status === 200) {
        console.log(res);
        setDueDetails(res?.data?.data);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    toCheckMonthlyDue(loggedInUser?.data?.hospitalProfile?._id);
  }, [loggedInUser]);
  if (dueDetails?.duepaid || loader || dueDetails== undefined) {
    return null;
  }
  console.log(dueDetails);

  return (
    <section className="bg-green-50  rounded-2xl  flex flex-col border border-gray-200 divide-y m-4 md:m-0 md:mb-4">
      <header className="  p-4 pb-2 ">
        <h1 className="text-coalBlack subHeadingText ">Amount Due</h1>
      </header>

      {dueDetails?.PendingDueRecords?.map((data) => (
        <div className="p-4 py-2">
          <p className="baseText text-coalBlack">
            Rupees
            <BsCurrencyRupee className="inline-block" />
            {data?.item?.total_amount} amount due for the month of{' '}
            {moment(data?.item?.createdAt).format('MMMM')}.
          </p>
          <span className="flex items-center gap-x-1 mt-1 baseText text-coalBlack">
            Status:{' '}
            <span className="text-center capitalize smallText rounded p-0.5 px-1 inline-block text-blue-600 bg-blue-200 ">
              {data?.item?.status}
            </span>
          </span>
        </div>
      ))}
    </section>
  );
}

export default WithdrawRequest;
