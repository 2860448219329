import React from 'react';
import { FcPaid } from 'react-icons/fc';
import { BsCalendarDate } from 'react-icons/bs';
import { FcHighPriority } from 'react-icons/fc';
function NoShowConsultation({ onclick }) {
  return (
    <div
      className="p-4 flex gap-x-3 border-b border-gray-200 cursor-pointer"
      onClick={onclick}
    >
      <div className="bg-red-100 rounded-full size-10 min-w-10 inline-flex justify-center items-center">
        <FcHighPriority className="text-2xl m-auto" />
      </div>
      <div>
        <p className="inline-block baseText text-coalBlack sm:!leading-5">
          You have been reported as no show for your consultation with <span className='capitalize'>sachin
          Tendulkar multi Specialty hospital</span> and 12/06/2024 (requested date)
        </p>
        <div>
          <BsCalendarDate className="text-sm inline-block me-2" />
          <small>24/09/2024</small>
        </div>
      </div>
    </div>
  );
}

export default NoShowConsultation;
