import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

function Dropdown({ title, options , selectFunction}) {
  let optionsBlock;
  optionsBlock = options?.map((data,i) => {
    return (
      <p
        className="hover:bg-slate-50/50 px-4 select-none cursor-pointer transition-colors ease-in-out duration-75 py-1 border-b border-blue-50/25"
        data-option-value={data.value}
        onClick={()=>selectFunction(data.value)} key={i}>
        {data.option}
      </p>
    );
  });
  return (
    <div className="inline-grid grid-cols-1 items-center min-w-48 gap-4 p-4 py-2 min-h-11 rounded-lg bg-accent text-white relative group/drop cursor-pointer">
      <div className='inline-flex gap-4 items-center'>
        <p className="text-base text-white font-normal capitalize">{title}</p>
        <div className='ms-auto'>
          <FiChevronDown />
        </div>
      </div>
      <div className="absolute top-8 left-0 w-full bg-accent text-white pt-2   rounded-lg hidden group-hover/drop:block">
        {optionsBlock}
      </div>
    </div>
  );
}

Dropdown.defaultProps = {
  title: 'drop down',
};

export default Dropdown;
