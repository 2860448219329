import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BiSolidDollarCircle } from 'react-icons/bi';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { useFormik } from 'formik';
import SimpleButton from '../common/buttons/SimpleButton/SimpleButton';
import { EditProfileLoader, Loader } from '../common/loader';
import { InputErrorMessage } from '../errorMessages';
import { FiChevronLeft } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import MainOddsPopup from '../common/oddsPopup/MainOddsPopup';
import { createSubscription, getSubscriptionPlan } from '../../apis';
import * as yup from 'yup';
import { AppContext } from '../../appContext';
import useRazorpay from 'react-razorpay';
import RenewSubscription from './RenewSubscription';
import { TbAlertCircleFilled } from 'react-icons/tb';
import ActiveSubscription from './ActiveSubscription';
function Subscription({ closeFunction }) {
  const { loggedInUser } = useContext(AppContext);
  const [loader, setLoader] = useState(true);
  const [btnLoader, setBtnLoader] = useState(false);
  const [plan, setPlan] = useState([]);
  const [renewStatus, setRenewStatus] = useState(null);
  let activeBg = 'bg-[#438883]/[10%]';
  let inActiveBg = 'bg-[#F7F7F7]';
  let activeText = 'text-[#438883]';
  let inActiveText = 'text-[#888888]';
  const planTypes = plan.map((data) => data.razarPayPlanId);
  const subscription = yup.object({
    subscription: yup
      .string()
      .oneOf(planTypes, 'Please select a subscription plan to continue')
      .required('Please select a subscription plan to continue'),
  });
  const formik = useFormik({
    initialValues: {
      subscription: '',
    },
    validationSchema: subscription,

    onSubmit: async (values) => {
      const { subscription } = values;
      setBtnLoader(true);
      if (values.subscription === '') {
        toast.error('Please select Any One');
        setBtnLoader(false);
      }
      console.log(values);
      const payload = {
        plan_id: subscription,
        hospitalId: loggedInUser?.data?.hospitalProfile?._id,
      };
      console.log(payload);
      const res = await createSubscription(payload);
      // toast.success(subscription);
      if (res.status === 200) {
        console.log(res?.data?.result);
        console.log(res?.data?.result?.razarPaySubscriptionId);
        handlePayment(res?.data?.result);
      }
    },
  });
  const[plancreate,setplancreate]=useState(false);
  const { handleSubmit, handleChange, values, touched, errors } = formik;
  const subscriptionTabs = (
    <form className="block ">
      <section className="space-y-4">
        {plan.map((data, i) => (
          <label
            htmlFor={data?.planName}
            className={`p-4 rounded-2xl border border-gray-200 relative max-w-sm mx-auto flex items-start min-h-28 gap-4 cursor-pointer ${
              formik.values.subscription === data?.razarPayPlanId
                ? activeBg
                : inActiveBg
            } `}
            key={i}
          >
            <input
              type="radio"
              name="subscription"
              id={data?.planName}
              value={data?.razarPayPlanId}
              checked={formik.values.subscription === data?.razarPayPlanId}
              onChange={handleChange}
              hidden
            />
            <div
              className={`inline-flex justify-center items-center size-16 min-w-16 rounded-full ${
                formik.values.subscription === data.razarPayPlanId
                  ? 'bg-white'
                  : 'bg-transparent'
              }`}
            >
              {/* <BiSolidDollarCircle className="size-9 text-gray-500" /> */}
              <div className="size-9 rounded-full">
                {data?.planIcon != '' ? (
                  <img
                    src={data?.planIcon}
                    className="size-9 rounded-full object-cover"
                  />
                ) : null}
              </div>
            </div>
            <div
              className={
                formik.values.subscription === data.razarPayPlanId
                  ? activeText
                  : inActiveText
              }
            >
              <p className="subHeadingText  capitalize">{data?.planName}</p>

              <p className="baseText  capitalize">{data?.planDescription}</p>
              <div className="flex items-center mt-1">
                {/* <p className='baseText capitalize' ></p> */}
                <p className="baseText !font-medium capitalize">
                  {data?.period} ₹{data?.planAmount}
                </p>
              </div>
            </div>
            <div
              className={`absolute top-3 right-3 ${
                formik.values.subscription === data.razarPayPlanId
                  ? 'visible'
                  : 'invisible'
              }`}
            >
              <IoIosCheckmarkCircle className="text-2xl text-[#438883]" />
            </div>
          </label>
        ))}
      </section>
      <div className="my-10 mb-32 max-w-sm mx-auto">
        <p className="mb-2">
          <InputErrorMessage
            error={formik.touched.subscription && formik.errors.subscription}
          />
        </p>
        <SimpleButton
          title={btnLoader ? <EditProfileLoader /> : 'Proceed To Pay'}
          onClickEvent={handleSubmit}
          buttonType={'primary'}
          customClass={'w-full rounded-2xl '}
        />
      </div>
    </form>
  );
  const getPlan = async () => {
    const res = await getSubscriptionPlan();
    if (res.status == 200) {
      console.log(res?.data?.result);
      setPlan(res?.data?.result);
      setLoader(false);
    }
  };
  useEffect(() => {
    getPlan();
    // alert(JSON.stringify(loggedInUser?.data?.hospitalProfile?.subscriptionId?.status))
    setRenewStatus(loggedInUser?.data?.hospitalProfile?.subscriptionId);
  }, [loggedInUser]);
  const [Razorpay] = useRazorpay();
  const handlePayment = useCallback(
    (order) => {
      const order_id = order.razarPaySubscriptionId;
      // Ensure order.id is defined
      if (!order_id) {
        console.error('Order ID is required for payment.');
        return;
      }
      const amountInPaise = order.amount * 100; // Convert amount to paise (smallest currency unit)
      const options = {
        key: process.env.REACT_APP_RAZARPAY_ID, // Your Razorpay key
        amount: amountInPaise.toString(), // Amount in paise
        currency: 'INR',
        name: 'ODDS',
        //description: "Test Transaction",
        // image: LogoImage,
        subscription_id: order_id,
        handler: (response) => {
          setBtnLoader(false);
          console.log('Payment Success', response);
          // handelConfirmPopUp();
          const payload = {
            hospitalId: order.hospitalid,
            subscription_id: order_id,
          };
          closeFunction();
          toast.success('Subscription added successfully');
          location.reload();
        },
        prefill: {
          name: order.name,
          // email: "youremail@example.com",
          contact: order.phoneno,
        },
        // notes: {
        //   address: "Razorpay Corporate Office",
        // },
        theme: {
          color: '#1648CE',
        },
        modal: {
          ondismiss: () => {
            // handelConfirmPopUp();
            console.log('Payment Failed or Closed');
            toast.error('Payment Cancelled');
            // closeFunction();
            // getItem();
            setBtnLoader(false);
          },
        },
      };

      const rzpay = new Razorpay(options);
      rzpay.open();
    },
    [Razorpay]
  );
  console.log(renewStatus);
  return (
    <>
      <MainOddsPopup closeFunction={() => closeFunction()}>
        <section className="lg:min-w-[420px]">
          {' '}
          <header className="p-4 sticky top-0 bg-white lg:px-0 lg:static z-10">
            <div className="flex items-center">
              <div className="inline-flex gap-1 item-center">
                <div className="active:bg-sky-50 lg:hidden size-9 rounded-full ">
                  <FiChevronLeft
                    className="text-3xl  text-coalBlack  p-1 rounded-full m-auto flex justify-center items-center"
                    onClick={() => {
                      closeFunction(false);
                    }}
                  />
                </div>
                <p className="headingText text-coalBlack">Subscription</p>
              </div>
              <div className="bg-white p-1 ms-auto rounded-xl border border-gray-300 lg:hidden">
                <IoIosHelpCircleOutline className="text-2xl text-primary_v2" />
              </div>
              <p
                className="hidden lg:block ms-auto baseText !font-medium cursor-pointer"
                onClick={() => {
                  closeFunction(false);
                }}
              >
                Discard
              </p>
            </div>
          </header>
          <div className="p-4 lg:p-0 h-full min-h-screen lg:min-h-[auto] lg:py-8 bg-white " style={{paddingBottom:'25%'}}>
            {loader ? (
              <div className="min-h-[50vh] flex justify-center items-center">
                <Loader />
              </div>
            ) : (
              <>
                {plancreate ? (
                  subscriptionTabs
                ) : (
                  {
                    'completed': (
                      <RenewSubscription
                        resetRenewStatus={setRenewStatus}
                        renewStatus={renewStatus}
                        closeFunction={closeFunction}
                        setplancreate={setplancreate}
                      />
                    ),
                    'active': (
                      <ActiveSubscription
                        resetRenewStatus={setRenewStatus}
                        renewStatus={renewStatus}
                        subscriptionId={renewStatus?._id}
                      />
                    ),
                    'pending': (
                      <SubscriptionCancelStatus />
                    )
                  }[renewStatus?.status] || subscriptionTabs
                )}
              </>
            )}

           
          </div>
        </section>
      </MainOddsPopup>
    </>
  );
}

function SubscriptionCancelStatus() {
  return (
    <p className='rounded-lg p-2 bg-red-50 text-red-500 max-w-md baseText'>
      <TbAlertCircleFilled className='inline-block !text-xl text-red-500 me-1 ' />
      Our system will update your account subscription status to reflect this change. This
      process might take up to sometimes.
    </p>
  );
}

export default Subscription;
