import React from 'react';
import { BiBell } from 'react-icons/bi'; // Ensure you have react-icons installed
import UserProfile from '../UserProfile/UserProfile';

function VerifyScreen({
  title,
  subTitle,
  imgSrc,
  btntext,
  btnFunction,
  nextStep,
  prevStep,
  profileData,
  setprofileData
}) {
  return (
    <section className="lg:w-1/2">
      <div className="p-4 w-full max-w-[400px] mx-auto">
        <UserProfile mailId={profileData?.email} />
        <div className="mt-10 flex flex-col gap-2 bg-white rounded-2xl py-7 px-4 shadow-blue_dropshadow md:shadow-none">
          <div className="flex gap-2 items-center">
            <div className="bg-purple-100 p-1 rounded-full">
              <BiBell className="text-purple-600 text-lg" />
            </div>
            <p className="text-coalBlack font-medium">{title}</p>
          </div>
          <p className="font-normal text-sm text-gray-500">{subTitle}</p>
          <div>
            <img
              src={imgSrc}
              className="object-contain m-auto aspect-square h-72 w-full "
            />
          </div>
          <button
            type="button"
            className="text-purple-600 font-medium bg-[#E5DFFF] px-6 py-2 min-h-[50px] rounded-xl w-full max-w-xs mx-auto"
            onClick={() => btnFunction()}
          >
            {btntext}
          </button>
        </div>
      </div>
    </section>
  );
}

export default VerifyScreen;
