import React from 'react';
import { FcPaid } from 'react-icons/fc';
import { BsCalendarDate } from 'react-icons/bs';
import moment from 'moment';
const CompletedConsultation = ({ data, onclick }) => {
  // console.log(data);
  // console.log(data?.consultation_id[0]?._id);

  return (
    <div
      className="p-4 flex gap-x-3 border-b border-gray-200 cursor-pointer"
      onClick={onclick}
    >
      <div className="bg-green-100 rounded-full size-10 min-w-10 inline-flex justify-center items-center">
        <FcPaid className="text-2xl m-auto" />
      </div>
      <div className='grow'>
        <p className="inline-block baseText text-coalBlack sm:!leading-5">
          <span className='capitalize'>{data?.Specalaization[0]?.name}</span> consultation was successfully closed
          with {''}
          <span className="capitalize">
            Dr {data?.accepted_doctor?.DoctorName}
          </span>
        </p>
        <div className="flex items-center pe-2">
          <>
            <BsCalendarDate className="text-sm inline-block me-2" />
            <small>{moment(data?.updatedAt).format('DD-MM-YYYY')}</small>
          </>
          <p className="ms-auto inline-block">₹ {data?.consultation_fee}</p>
        </div>
      </div>
    </div>
  );
};

export default CompletedConsultation;
