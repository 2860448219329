import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowReturnLeft, CircleFill, Send } from 'react-bootstrap-icons';
import leftArrow from '../../../../Icons/leftArrow.svg';
import addChatText from '../../../../Icons/addChatText.svg';
import { useInterval } from 'usehooks-ts';
import { MessageCollectionEventHandler, MessageFilter } from '@sendbird/chat/groupChannel';
import { GetTimeSent, GetReadStatus } from '../../utils';


const Container = styled.div`
  width: 370px;
  height: calc(100% - 150px);
  max-height: 590px;
  overflow: hidden;
  position: fixed;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 3%;
  background-color: #e6cb47;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
`;

const GoBack = styled.div`
  padding: 15px;
  background-color: #e6cb47;
  clear: both;
  &:hover {
    pointer: cursor;
  }
`;

const MessageList = styled.div`
  height: 80%;
  overflow-y: auto;
  background-color: white;
  background-size: 100%;
  padding: 40px 0px;
`;

const UserInput = styled.form`
  width: 100%;
  display: flex;
`;

const MessageInput = styled.textarea`
  width: 90%;
  height: 36px;
  resize: none;
  padding: 5px;
  background-color: #EEE;
  border-radius: 30px;
`;

const SendButton = styled.div`
  padding: 10px;
  background: transparent;
  &:hover {
    pointer: cursor;
  }
`;

const ChatLabel = styled.div`
  font-weight: 600;
  text-align: right;
  vertical-align: middle;
  margin-right: 20px;
`;

const OnlineStatus = styled.span`
  margin-left: 5px;
`;

const MessageContainer = styled.div`
  clear: both;
  margin-bottom: 40px;
  margin-left: 15px;
  margin-right: 15px;
`;

const MessageSender = styled.div`
  color: rgb(102, 102, 102);
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const MessageContent = styled.div`
  // background-color: rgb(102, 102, 102);
  font-size: 15px;
`;

const TimeSent = styled.a`
  pointer: cursor;
  font-size: 10px;
  color: rgb(25, 145, 235);
  font-weight: 600;
  &: hover {
    pointer: cursor;
    text-decoration: underline;
    text-decoration-color: rgb(25, 145, 235);
  }
  margin-left: 10px;
`;

const ReplyButton = styled.button`
  margin-left: 10px;
`;

const ReplyToSender = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: white;
`;

const ReplyToContent = styled.div`
  font-size: 13px;
  color: gray;
`;

const ReplyToContainer = styled.div`
  background-color: black;
  padding: 5px;
  display: inline-flex;
  justify-content: space-between;
`;

const ReplyToCloseButton = styled.button`
  background: transparent;
  font-size: 16px;
  color: white;
  margin-right: 15px
`;

const QuoteContainer = styled.div`
  color: gray;
`;
const QuoteHeader = styled.div`
  font-size: 11px;
  font-weight: 600;
`;

const QuoteBody = styled.div`
  font-size: 12px;
  font-weight: normal;
`;

const Chat = ({
  goBack,
  sendBird,
  channel,
  userId,
  ...props
}) => {
  const [online, setOnline] = useState(false);
  const [peerText, setPeerText] = useState('');
  const [peerCount, setPeerCount] = useState(0);
  const [collection, setCollection] = useState({});
  const [messages, setMessages] = useState([{}]);
  const [newMessages, setNewMessages] = useState([{}]);
  const [peers, setPeers] = useState({});

  const [draft, setDraft] = useState('');
  const [replyTo, setReplyTo] = useState({});

  const [typing, setTyping] = useState({
    status: false,
    last: 0
  });
  const [typingUsers, setTypingUsers] = useState([]);

  
  const handler = {
    onMessagesAdded: (context, channel, newMessages) => {
    }
  };

  useInterval(() => {
    async function refreshGroup () {
      if (typing.status) {
        const typingElapsedTime = Math.floor((Date.now() - typing.last) / 1000);
  
        if(typingElapsedTime >= 5) { // if user last typed more than 5 seconds ago
          channel.endTyping();
          setTyping({
            status: false,
            last: 0
          });
        }
      }

      const tUsers = channel.getTypingUsers()?.map((user) => {
        return user.nickname;
      });

      setTypingUsers(tUsers);

      await channel.refresh();

      const peers = channel.members.reduce((acc, curr) => {
        if (userId !== curr.userId) {
          acc.push(curr);
        }
        return acc;
      }, []);

      if (peers.length > 0) {
        peers[0].connectionStatus === 'online' ? setOnline(true) : setOnline(false);
      }
      
      
      const params = {
        limit: 20,
        reverse: false,
        messageTypeFilter: '',
        includeThreadInfo: true,
        replyType: 'all',
        includeParentMessageInfo: true
        // ...
      };
      const query = channel.createPreviousMessageListQuery(params);
      try {
        const rmessages = await query.load();
        await channel.markAsRead();
        setMessages(rmessages);
      } catch(e) {
        // Handle error
      }
    }
    // if (peerCount === 1) {
    refreshGroup();
    // }
  }, 3000);

  useEffect(() => {
    async function getMessages() {

      const filter = new MessageFilter();
      const limit = 100;
      const startingPoint = Date.now();
      const col = channel.createMessageCollection({
        filter,
        limit,
        startingPoint,
      });

      col.setMessageCollectionHandler(handler);

      setCollection(col);

      const peers = channel.members.reduce((acc, curr) => {
        if (userId !== curr.userId) {
          acc.push(curr);
        }
        return acc;
      }, []);
    
      if (peers.length === 1) {
        // peerText = peers[0].nickname;
        setPeerText(peers[0].nickname);
        peers[0].connectionStatus === 'online' ? setOnline(true) : setOnline(false);
      } else if (peers.length >= 1) {
        // peerText = channel._name;
        setPeerText(channel?._name);
      }
      setPeerCount(peers.length);
      setPeers(peers.reduce((acc, curr) => {
        acc[curr.nickname] = curr.userId;
        return acc;
      }, {}));

      const params = {
        limit: 20,
        reverse: false,
        messageTypeFilter: '',
        // ...
      };
      const query = channel.createPreviousMessageListQuery(params);
      try {
        const oldMessages = await query.load();
        setMessages(oldMessages);
      } catch(e) {
        // Handle error
      }
    }

    getMessages();
  }, []);

  return (
    <>
      <div className='chat-header'>
        <div className='chat-header-items'>
          <div onClick={() => goBack()}>
            <img src={leftArrow}/>
          </div>
          <h4>{channel._name}</h4>
          <ChatLabel>
            {peerText}
            {
              peerCount === 1 ? 
                <OnlineStatus><CircleFill color={online ? 'green' : 'red'} fontSize={8} /></OnlineStatus> :
                <></>
            }
          </ChatLabel>
        </div>
      </div>
      <div className='chat-body'>
        <MessageList>
          {
            messages?.map((msg, index) => {
              let self = false;
              if(msg.sender) {
                self = msg.sender.userId === userId;
                return (
                  <MessageContainer key={msg.messageId}>
                    <MessageSender>                        
                      {self ? 'You' : msg.sender.nickname}
                      <TimeSent>{GetTimeSent(msg.createdAt)}</TimeSent>
                      {
                        GetReadStatus(msg.sendingStatus, channel?.getUnreadMemberCount?.(msg))
                      }
                    </MessageSender>
                    <div style={{clear: 'both'}}></div>
                    <MessageContent>
                      {msg.message}
                    </MessageContent>
                  </MessageContainer>);
              }
              return (<div key={index}></div>);
            })
          }
          {
            typingUsers.length > 0 ? 
              <div>{typingUsers.join(', ')} {typingUsers.length === 1 ? 'is' : 'are'} typing...</div> :
              <></>
          }
        </MessageList>
      </div>
      <div className='chat-footer'>
        <UserInput>
          <SendButton onClick={() => {
            if (draft.length > 0) {
              const params = {
                message: draft,
                parentMessageId: 0
              };
              if (typeof replyTo.parentMessageId !== 'undefined') {
                params.parentMessageId = replyTo.parentMessageId;
                setReplyTo({});
              }
              const mentions = draft.match(/\B@\w+/g);
              if (mentions) {
                const mentionedUserIds =  mentions.reduce((mentions, mention) => {
                  const userId = mention.substring(1);
                  if (typeof peers[userId] !== 'undefined') {
                    mentions.push(peers[userId]);
                  }

                  return mentions;
                }, []);

                if (mentionedUserIds.length > 0) {
                  params.mentionedUserIds = mentionedUserIds;
                }
              }
              channel.sendUserMessage(params);
              setDraft('');
            }
          }}>
            <Send fontSize={28} />
          </SendButton>
          <MessageInput value={draft} onChange={(e) => {
            if (typing.status === false) {
              channel.startTyping();
            }
            setTyping({
              status: true,
              last: Date.now()
            });
            setDraft(e.target.value);
          }}></MessageInput>
          
        </UserInput>
      </div>
    </>
  );
};

export default Chat;