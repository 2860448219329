import React, { useState, useContext, useEffect, useCallback } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { ConsultationRequest, ConsultationRequest2 } from '../Validation';
import { EditProfileLoader } from '../common/loader';
import { FaLocationDot } from 'react-icons/fa6';
import { IoMdCall } from 'react-icons/io';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import {
  checkMonthlyDue,
  fileUploadApi,
  getCreditBasePrice,
  getSpecialization,
  getUserApi,
  getdoctorslistAPI,
  insertconsultationAPI,
  updateconsultationAPI,
} from '../../apis';
import { AppContext } from '../../appContext';
import { useNavigate } from 'react-router-dom';
import SelectType from './SelectType';
import PatientDetails from './PatientDetails';
import Avatar from '../Avatar/Avatar';
function Createrequest({ closeFunction, editFormValue, profileData, onload }) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [doctors, setdoctors] = useState(null);
  const [step, setStep] = useState(1);
  const [creditErrorBlock, setCreditErrorBlock] = useState({
    status: true,
    selectedType: 'In-Person',
    specializationCost: null,
    consultationCost: null,
  });
  const { loggedInUser, fetchUserProfile } = useContext(AppContext);
  const [remainingChars, setRemainingChars] = useState(100);
  const [remainingCharsdoc, setRemainingCharsdoc] = useState(1000);
  const [remainingmedicalailment, setRemainingmedicalailment] = useState(1000);
  const [startDate, setStartDate] = useState(new Date());
  const [creditDetails, setCreditDetails] = useState(null);
  const [specialization, setSpecialization] = useState([]);

  const specializationOption = specialization?.map((data) => {
    return { label: data?.name, value: data?._id };
  });
  const handleChangedescription = (e, setstateval, limit) => {
    const inputText = e.target.value;
    const remaining = limit - inputText.length;
    if (remaining >= 0) {
      setstateval(remaining);
    } else {
      setstateval(0);
    }
  };
  const getdoctorslist = async () => {
    try {
      const res = await getdoctorslistAPI();
      if (res.status === 200) {
        console.log(res.data.doctorProfile);
        setdoctors(res?.data?.doctorProfile);
      }
    } catch (error) {
      console.error(error);
    }
  };
  function getDoctorProfileById(id) {
    const doctor = doctors.find((profile) => profile._id === id);

    // Return the found doctor profile object, or null if not found
    return doctor || null;
  }
  const doctorOptions = doctors?.map((doctor) => ({
    value: doctor._id,
    label: doctor.DoctorName,
  }));
  const toGetCreditBasePrice = async () => {
    try {
      const res = await getCreditBasePrice();
      if (res.status === 200) {
        setCreditDetails(res?.data?.credit[0]);
        // if (
        //   loggedInUser?.data?.hospitalProfile?.creditbalance >
        //   res?.data?.credit[0]?.creditsPerInpersonConsultation
        // ) {
        //   console.log('inper sucss');
        // } else {
        //   console.log('buy cridet');
        //   setCreditErrorBlock({
        //     status: true,
        //     selectedType: 'in-person',
        //     creditUsage: res?.data?.credit[0]?.creditsPerInpersonConsultation,
        //   });
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const handleRecordSelect = async (recordType) => {
    formik.setFieldValue('Reporttype', recordType);
  };
  const GetSpecialization = async () => {
    try {
      const res = await getSpecialization();
      if (res.status === 200) {
        setSpecialization(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getdoctorslist();
    // toGetCreditBasePrice();
    // GetSpecialization();
  }, []);
  useEffect(() => {
    if (editFormValue != null) {
      setUploadedFiles(editFormValue?.record?.Details);
    }
  }, [editFormValue]);

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      addTag();
    }
  };

  const addTag = () => {
    const newTag = inputValue.trim();
    if (newTag) {
      const newMedicalTags = [...formik.values.medicalTags, newTag]; // Get current medicalTags from Formik values
      formik.setFieldValue('medicalTags', newMedicalTags); // Update medicalTags in Formik
      setInputValue('');
    }
  };

  const removeTag = (tagToRemove) => {
    const newMedicalTags = formik.values.medicalTags.filter(
      (tag) => tag !== tagToRemove
    ); // Get current medicalTags from Formik values
    formik.setFieldValue('medicalTags', newMedicalTags); // Update medicalTags in Formik
  };
  const toCheckMonthlyDue = async (id) => {
    setLoader(true);
    try {
      const res = await checkMonthlyDue(id);
      if (res.status === 200) {
        console.log(res);
        setLoader(false);
        return res?.data?.data;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };
  const formik2 = useFormik({
    initialValues: {
      consultationType: 'inperson',
      specialization: '',
      chosenSpecialization: '',
      specializationCredit: '',
      consultationPriority: 'regular',
    },
    validationSchema: ConsultationRequest,
    onSubmit: async (values) => {
      console.log(values);
      console.log(loggedInUser?.data?.Settings?.HospitalPayDate);
      let HospitalPayDate = loggedInUser?.data?.Settings?.HospitalPayDate;
      let currentData = new Date().getDay() + 1;

      if (!creditErrorBlock.status) {
        if (currentData >= HospitalPayDate) {
          const checkMonthlyDue = await toCheckMonthlyDue(
            loggedInUser?.data?.hospitalProfile?._id
          );
          console.log(checkMonthlyDue?.duepaid);
          if (checkMonthlyDue?.duepaid) {
            setStep(2);
          } else {
            toast.error('Pending Amount due');
          }
        } else {
          setStep(2);
        }
      }
    },
  });
  const formik = useFormik({
    initialValues: {
      requestedDate: null,
      ReferralDoctor: '',
      ReferralDoctorPhone: '',
      RequestDescription: editFormValue?.RequestDescription || '',
      medicalailment: editFormValue?.allignment || '',
      Age: editFormValue?.patientAge || '',
      gender: editFormValue?.patientGender || '',
      Reporttype: 'report',
      medicalTags: editFormValue?.medicalTag || [],
    },
    validationSchema: ConsultationRequest2,
    onSubmit: async (values) => {
      console.log(values);
      console.log(uploadedFiles);
      const {
        Age,
        gender,
        // consultationPriority,
        requestedDate,
        medicalTags,
        ReferralDoctor,
        ReferralDoctorPhone,
        RequestDescription,
        medicalailment,
      } = values;
      const {
        specialization,
        consultationType,
        consultationPriority,
      } = formik2.values;
      console.log(specialization);
      console.log(consultationType);
      const dateObject = new Date(requestedDate);
      // Get the formatted date in ISO string format
      const formattedDate = dateObject.toISOString();
      if (editFormValue !== null) {
        console.log('edit forms');
        try {
          setLoader(true);
          const res = await updateconsultationAPI({
            Id: editFormValue?._id,
            userId: profileData?._id || '',
            RequestedDate: formattedDate,
            hospitalId: profileData?.hospitalProfile?._id || '',
            hospitalName: profileData?.hospitalProfile?.hospitalName || '',
            hospitalcoverPhoto: profileData?.hospitalProfile?.coverPic || '',
            hospitalProfilePhoto:
              profileData?.hospitalProfile?.profilePic || '',
            hospitalAddress: profileData?.hospitalProfile?.address || '',
            hospitalemail: profileData?.email || '',
            hospitalContactNumber:
              profileData?.hospitalProfile?.refCountryCode +
                '' +
                profileData?.hospitalProfile?.refPhoneNo || '',
            hospitalLocation: { lat: '', lon: '' },
            consType: consultationType,
            RequestDescription: RequestDescription,
            allignment: medicalailment,
            doctorDetails: {
              doctorname: ReferralDoctor,
              phone: '+91' + ReferralDoctorPhone,
            },
            doctorNotes: DoctorsDescription,
            doctorNotesUpdatedAt: new Date(),
            patientAge: Age,
            patientGender: gender,
            priority: consultationPriority,
            record: {
              createdOn: new Date(),
              Details: uploadedFiles,
            },
            // "status": "pending",
            consultationDueDate: new Date(),
            medicalTag: medicalTags,
            isActive: true,
            isArchived: false,
          });
          if (res.status === 200) {
            toast.success(res.data.message);
            closeFunction();
            onload();
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
          const message =
            error?.response?.data?.message || error?.response?.statusText;
          toast.error(message, { id: 'error' });
        } finally {
          setLoader(false);
        }
      } else {
        try {
          setLoader(true);
          const res = await insertconsultationAPI({
            userId: profileData?._id || '',
            RequestedDate: formattedDate,
            hospitalId: profileData?.hospitalProfile?._id || '',
            hospitalName: profileData?.hospitalProfile?.hospitalName || '',
            hospitalAddress: profileData?.hospitalProfile?.address || '',
            hospitalcoverPhoto: profileData?.hospitalProfile?.coverPic || '',
            hospitalProfilePhoto:
              profileData?.hospitalProfile?.profilePic || '',
            hospitalemail: profileData?.email || '',
            hospitalContactNumber:
              profileData?.hospitalProfile?.refCountryCode +
                '' +
                profileData?.hospitalProfile?.refPhoneNo || '',
            consType: consultationType,
            hospitalLocation: { lat: '', lon: '' },
            RequestDescription: RequestDescription,
            allignment: medicalailment,
            doctorDetails: {
              doctorname: ReferralDoctor,
              phone: '+91' + ReferralDoctorPhone,
            },
            doctorNotes: 'disable',
            doctorNotesUpdatedAt: new Date(),
            patientAge: Age,
            patientGender: gender,
            priority: consultationPriority,
            record: {
              createdOn: new Date(),
              Details: uploadedFiles,
            },
            status: 'pending',
            Specalaization: specialization || '',
            consultationDueDate: new Date(),
            medicalTag: medicalTags,
            // AcceptedDoctor: '',
            isActive: true,
            isArchived: false,
          });
          if (res.status === 200) {
            fetchUserProfile();
            toast.success(res.data.message);
            closeFunction();
            onload();
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
          const message =
            error?.response?.data?.message || error?.response?.statusText;
          toast.error(message, { id: 'error' });
        } finally {
          setLoader(false);
        }
      }
    },
  });
  const handleChangeDatePicker = (name, value) => {
    formik.setFieldValue(name, moment(value).format('YYYY-MM-DD'));
  };
  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        console.log(formik.values.Reporttype);
        // Process each accepted file
        acceptedFiles.forEach((file, index) => {
          fileupload(
            file,
            index,
            acceptedFiles.length,
            formik.values.Reporttype
          );
        });
      }
    },
    [formik.values.Reporttype]
  );
  const [selectedOption, setSelectedOption] = useState(
    formik.values?.specialization != ''
      ? formik.values.specialization
      : 'select a Specialization'
  );
  const [uploadPercentage, setUploadPercentage] = useState([]);
  const [filename, setfileName] = useState([]);
  const [filesize, setfilesize] = useState([]);
  const [fileiserror, setfileiserror] = useState([]);
  const [isfile, setisfile] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  function formatFileSize(sizeInBytes) {
    const sizeInKB = sizeInBytes / 1024;
    const sizeInMB = sizeInKB / 1024;

    if (sizeInMB >= 1) {
      return `${sizeInMB.toFixed(2)} MB`;
    } else if (sizeInKB >= 1) {
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  }
  const onUploadProgress = (progressEvent, file, id) => {
    const loaded = progressEvent.loaded;
    const total = progressEvent.total;

    const percentage = Math.round((loaded / total) * 100);

    setUploadedFiles((prevUploadedFiles) => {
      return prevUploadedFiles.map((uploadedFile) => {
        if (uploadedFile.id === id) {
          return {
            ...uploadedFile,
            updatedProgress: percentage,
          };
        }
        return uploadedFile;
      });
    });
  };

  const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const fileupload = async (file, index, totalFiles, recordtype) => {
    const fileId = generateUniqueId(); // Assuming you have a function to generate a unique ID

    try {
      setisfile(true);
      setfileiserror((prevFileName) => {
        const updatedStatus = [...prevFileName];
        updatedStatus[index] = false;
        return updatedStatus;
      });
      console.log(recordtype);
      const fileDetails = {
        type: recordtype,
        id: fileId,
        fileName: file.name,
        filePath: '',
        fileSize: formatFileSize(file.size),
        updatedProgress: 0,
        isuploading: true, // Set isuploading to true initially
      };

      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        fileDetails,
      ]);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', fileId);

      const uploadResponse = await fileUploadApi(formData, (progressEvent) =>
        onUploadProgress(progressEvent, file, fileId)
      );

      if (uploadResponse.status === 200 || uploadResponse.status === 201) {
        setfileName((prevFileName) => {
          const updatedFileName = [...prevFileName];
          updatedFileName[index] = file.name;
          return updatedFileName;
        });

        setfilesize((prevFileSize) => {
          const updatedFileSize = [...prevFileSize];
          updatedFileSize[index] = formatFileSize(file.size);
          return updatedFileSize;
        });
        setUploadedFiles((prevUploadedFiles) => {
          return prevUploadedFiles.map((uploadedFile) => {
            if (uploadedFile.id === fileId) {
              return {
                ...uploadedFile,
                type: formik.values.Reporttype,
                isuploading: false,
                filePath: uploadResponse.data.filePath, // Assuming uploadResponse has filePath
              };
            }
            return uploadedFile;
          });
        });
      } else {
        setfileiserror((prevFileName) => {
          const updatedStatus = [...prevFileName];
          updatedStatus[index] = true;
          return updatedStatus;
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setfileiserror((prevFileName) => {
        const updatedStatus = [...prevFileName];
        updatedStatus[index] = true;
        return updatedStatus;
      });
    } finally {
      if (index === totalFiles - 1) {
        setisfile(false);
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const removeFile = (indexToRemove) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const { handleSubmit, handleChange, values, touched, errors } = formik;

  let createBtnText = 'Create Request';
  if (editFormValue != null) {
    createBtnText = 'Update Request';
  }
  const handleOption = (option) => {
    setSelectedOption(option);
    formik.setFieldValue('specialization', option.value);
  };
  return (
    <section className="bg-black/25 fixed top-0 left-0 w-full h-screen z-50 flex justify-center items-center  lg:z-[10000]">
      <div
        className="absolute top-0 left-0 w-full h-full bg-inherit"
        onClick={closeFunction}
      ></div>
      <div className="bg-bodybackground relative z-20 m-auto overflow-y-auto scrollbar w-full max-h-screen lg:max-w-lg lg:py-4 lg:px-8 lg:max-h-[96vh]  lg:rounded-3xl">
        {/* LG Navigation  */}
        <div className="Navigation justify-between items-center hidden py-4 lg:flex ">
          <p className="text-coalBlack text-lg font-medium">
            {step === 2 ? (
              <FiChevronLeft
                className="text-lg inline-block text-coalBlack w-9 h-9 p-1 rounded-full"
                onClick={
                  step === 1
                    ? closeFunction
                    : () => {
                      setStep(1);
                    }
                }
              />
            ) : null}
            {step === 1 ? 'New request' : 'Back'}
          </p>
          <p
            className="text-gray-500 text-sm font-medium min-w-10 p-2 cursor-pointer"
            onClick={closeFunction}
          >
            Close
          </p>
        </div>
        {/* LG profile viewer  */}
        <div className="profileForLargeDevice rounded-2xl bg-white p-4 space-y-3 mb-4 hidden lg:block">
          <div>
            {profileData?.hospitalProfile?.profilePic ? (
              <img
                className="h-36 rounded-2xl bg-white w-[100%] object-cover"
                src={profileData?.hospitalProfile?.profilePic}
              />
            ) : (
              <div className="bg-gray-100 h-36 rounded-2xl ">
                <p className="py-6 text-center line-clamp-2 baseText capitalize">
                  {' '}
                  {profileData?.hospitalProfile?.hospitalName}
                </p>
              </div>
            )}
            {profileData?.hospitalProfile?.profilePic ? (
              <img
                className="w-14 h-14 bg-white rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border-[3px] object-cover border-white"
                src={profileData?.hospitalProfile?.profilePic}
              />
            ) : (
              <div className="  size-14 bg-gray-200 flex justify-center items-center  rounded-full relative top-[-30px] left-1/2 -translate-x-1/2 border border-white">
                <p className="baseText capitalize">
                  {profileData?.hospitalProfile?.hospitalName[0]}
                </p>
              </div>
            )}
          </div>
          <div className="px-6 space-y-3">
            <p className="text-coalBlack text-center font-medium text-xl capitalize line-clamp-2">
              {profileData?.hospitalProfile?.hospitalName}
            </p>

            <div className="flex items-start gap-2">
              <FaLocationDot className="text-xl text-accent min-w-4" />
              <p className="text-base font-medium text-gray-400">
                {profileData?.hospitalProfile?.address}
              </p>
            </div>
            <div className="flex items-start gap-2">
              <IoMdCall className="text-xl text-accent min-w-4" />
              <p className="text-base font-medium text-gray-400">
                {profileData?.hospitalProfile?.refCountryCode}
                {profileData?.hospitalProfile?.refPhoneNo}
              </p>
            </div>
          </div>
        </div>
        <main className="bg-white p-4 pt-0 pb-16  lg:rounded-2xl lg:py-5 md:min-h-screen lg:min-h-[auto]">
          {/* SM Navigation  */}
          <div className=" bg-white flex items-center gap-2 sticky top-0 py-2 z-30 lg:hidden">
            <FiChevronLeft
              className="text-2xl text-coalBlack w-9 h-9 p-1 rounded-full active:bg-sky-50"
              onClick={
                step === 1
                  ? closeFunction
                  : () => {
                    setStep(1);
                  }
              }
            />
            <p className="text-lg font-medium text-coalBlack capitalize">
              {step === 1 ? 'New request' : 'Back'}
            </p>
          </div>
          {/* SM profile viewer  */}
          <div className="profileForSmallDevice flex items-center gap-2 my-2 py-2 rounded-2xl shadow-blue_dropshadow lg:hidden">
            {profileData?.hospitalProfile?.profilePic ? (
              <img
                className="size-10 bg-blue-400 rounded-full object-cover"
                src={profileData?.hospitalProfile?.profilePic}
              />
            ) : (
              // <div className="min-w-10 h-10 bg-blue-400 bg-avatar rounded-full"></div>
              <Avatar name={profileData?.hospitalProfile?.hospitalName[0]} />
            )}
            <p className="text-base font-medium text-[#333] max-w-[70%]">
              {profileData?.hospitalProfile?.hospitalName}
            </p>
          </div>
          {step === 2 ? (
            <p className="text-lg font-medium text-coalBlack ">
              Request Description
            </p>
          ) : null}

          {step === 1 ? (
            <SelectType
              formik={formik2}
              creditErrorBlock={creditErrorBlock}
              setCreditErrorBlock={setCreditErrorBlock}
              btnLoader={loader}
            />
          ) : (
            <PatientDetails
              formik={formik}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              loader={loader}
            />
          )}
        </main>
        {/* LG Submit btn  */}
        <div className="bg-bodybackground my-2 py-2  sticky -bottom-4 hidden lg:block z-20">
          <div className="bg-white p-4 rounded-2xl">
            <div className="flex items-center gap-4">
              <button
                className="btn btn_primary_black text-white w-full rounded-xl"
                type={!loader ? 'submit' : 'button'}
                onClick={(e) => {
                  e.preventDefault();
                  if (!loader) {
                    {
                      step === 1
                        ? formik2.handleSubmit()
                        : formik.handleSubmit();
                    }
                  }
                }}
              >
                {!loader ? (
                  step === 1 ? (
                    'Continue'
                  ) : (
                    'Create New Request'
                  )
                ) : (
                  <div className="text-xs">
                    <EditProfileLoader />
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Createrequest;
