import React from 'react';

const Tab = ({ label, isActive, onClick }) => {
  return (
    <div
      className={`inline-block p-4 cursor-pointer ${
        isActive
          ? 'text-blue-600 bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500'
          : 'rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300'
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default Tab;
