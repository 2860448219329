import React, { useState } from 'react';
import { BiSolidReport } from 'react-icons/bi';
import { FaFilePrescription } from 'react-icons/fa';
import { GrNotes } from 'react-icons/gr';

function RecordSelector({ onSelect,defaultvalue }) {
  const [selected, setSelected] = useState(defaultvalue);

  const handleClick = (type) => {
    setSelected(type);
    onSelect(type);
  };

  return (
    <div className="flex items-center gap-6">
      <div
        className={`inline-flex flex-col gap-1 justify-center items-center cursor-pointer ${
          selected === 'report' ? 'text-accent' : 'text-gray-400'
        }`}
        onClick={() => handleClick('report')}
      >
        <BiSolidReport className="text-2xl" />
        <p className="text-sm font-normal capitalize">Report</p>
      </div>
      <div
        className={`inline-flex flex-col gap-1 justify-center items-center cursor-pointer ${
          selected === 'prescription' ? 'text-accent' : 'text-gray-400'
        }`}
        onClick={() => handleClick('prescription')}
      >
        <FaFilePrescription className="text-2xl" />
        <p className="text-sm font-normal capitalize">Prescription</p>
      </div>
      <div
        className={`inline-flex flex-col gap-1 justify-center items-center cursor-pointer ${
          selected === 'notes' ? 'text-accent' : 'text-gray-400'
        }`}
        onClick={() => handleClick('notes')}
      >
        <GrNotes className="text-2xl" />
        <p className="text-sm font-normal capitalize">Notes</p>
      </div>
    </div>
  );
}

export default RecordSelector;
