import React, { useContext, useEffect, useState } from 'react';
import {
  useWeavy,
  WyChat,
  WyNotifications,
  WyNotificationToasts,
  Weavy,
} from '@weavy/uikit-react';
import { getOddsWeavyTokenApi } from '../../../apis';
import { AppContext } from '../../../appContext';
import './weavyStyle..css';
import { EditProfileLoader, Loader } from '../../common/loader';

function OddsChat({ data, consultationId }) {
  // console.log(data);
  const [hospitalToken, setHospitalToken] = useState(null);
  const [doctorToken, setDoctorToken] = useState(null);
  const { loggedInUser } = useContext(AppContext);
  // console.log(loggedInUser?.data?.hospitalProfile?.hospitalName);
  // console.log(loggedInUser?.data?.hospitalProfile?._id);
  const getWeavyToken = async (user) => {
    try {
      const res = await getOddsWeavyTokenApi(user);
      if (res.status === 200 || res.status === 201) {
        return res.data.token;
      }
    } catch (err) {
      console.error('Error:', err);
      throw err;
    }
  };

  useEffect(() => {
    const initializeChat = async () => {
      try {
        const hospitalDetails = {
          userId: loggedInUser?.data?.hospitalProfile?._id,
          username: loggedInUser?.data?.hospitalProfile?.hospitalName,
        };
        const doctorDetails = {
          userId: consultationId,
          username: data?.DoctorName.replace(/\s+/g, ''),
        };

        const isHospitalToken = await getWeavyToken(hospitalDetails);
        const isDoctorToken = await getWeavyToken(doctorDetails);

        setHospitalToken(isHospitalToken);
        setDoctorToken(isDoctorToken);

        localStorage.setItem('hospitalChatToken', isHospitalToken);
        localStorage.setItem('doctorChatToken', isDoctorToken);
        // Initialize Weavy instance
        const weavy = new Weavy();
        weavy.notificationEvents = true;
        weavy.url = process.env.REACT_APP_WEAVY_URL;
        weavy.tokenFactory = async () =>
          localStorage.getItem('hospitalChatToken');

        // Clean up Weavy instance on unmount
        return () => {
          console.log('Cleaning up Weavy instance');
          weavy.destroy();
        };
      } catch (err) {
        console.error('Error initializing chat:', err);
      }
    };

    initializeChat();
  }, [loggedInUser, consultationId, data]);

  // useWeavy({
  //   url: process.env.REACT_APP_WEAVY_URL,
  //   tokenFactory: () => localStorage.getItem('hospitalChatToken'),
  // });

  return (
    <>
      {hospitalToken && doctorToken ? (
        <>
          <WyChat
            uid={consultationId}
            style={{
              '--wy-border-radius-top-right': '0px !important',
            }}
            className="h-dvh max-h-dvh pt-16 overscroll-contain lg:h-[96dvh]"
            autoCapitalize={'true'}
            autoFocus={'true'}
            name="chat"
          ></WyChat>
        </>
      ) : (
        <div className="min-h-[100vh] w-full flex justify-center items-center">
          <Loader />
        </div>
      )}
    </>
  );
}

export default OddsChat;
